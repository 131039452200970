import React, { Component, useState, useEffect } from 'react';
import { Line, Bar, Doughnut, Pie, Scatter, Polar, Radar } from 'react-chartjs-2';

import moment from 'moment'


export const AggregatedDataCharts = (props) => {

    const [totalFuel, setTotalFuel] = useState([]);
    const [avgFuelPercent, setAvgFuelPercent] = useState([]);
    const [totalCarbonEmission, setTotalCarbonEmission] = useState([]);
    const [dates, setDates] = useState([]);
    const [arr, setArr] = useState(props.data.slice().reverse());


    const [color, setColor] = useState(
        ['rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)']
    );
    const [colorBorder, setColorBorder] = useState(
        ['rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)']
    );

    const dynamicColors = () => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        let color = "rgba(" + r + ", " + g + ", " + b + ", 0.2)";
        let border = "rgba(" + r + ", " + g + ", " + b + ", 1)";
        setColor(x => [...x, color]);
        setColorBorder(x => [...x, border]);
    };

    const render = () => {
        arr.forEach(current => {
            totalFuel.push(current.TotalFuel / 1000);
            avgFuelPercent.push(current.AverageFuelPercent);
            totalCarbonEmission.push(current.TotalCarbonEmission);
            dates.push(moment(current.Date).format('MM/YYYY'));
            dynamicColors();
        });
    }

    useEffect(() => {
        render()
    }, []);


    const barData = {
        labels: dates,
        datasets: [{
            label: '% Avg Fuel',
            data: avgFuelPercent,
            backgroundColor: color,
            borderColor: colorBorder,
            borderWidth: 1,
            fill: false
        }]
    };

    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                gridLines: {
                    color: "rgba(204, 204, 204,0.1)"
                }
            }],
            xAxes: [{
                gridLines: {
                    color: "rgba(204, 204, 204,0.1)"
                }
            }]
        },
        legend: {
            display: false
        },
        elements: {
            point: {
                radius: 0
            }
        }
    }

    const areaData = {
        labels: dates,
        datasets: [{
            label: 'x1000',
            data: totalFuel,
            backgroundColor: color,
            borderColor: colorBorder,
            borderWidth: 1,
            fill: true, // 3: no fill
        }]
    };

    const areaOptions = {
        plugins: {
            filler: {
                propagate: true
            }
        },
        scales: {
            yAxes: [{
                gridLines: {
                    color: "rgba(204, 204, 204,0.1)"
                }
            }],
            xAxes: [{
                gridLines: {
                    color: "rgba(204, 204, 204,0.1)"
                }
            }]
        }
    }

    const lineData = {
        labels: dates,
        datasets: [{
            data: totalCarbonEmission,
            backgroundColor: color,
            borderColor: colorBorder,
            borderWidth: 1,
            fill: false
        }]
    };

    const lineOptions = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                gridLines: {
                    color: "rgba(204, 204, 204,0.1)"
                }
            }],
            xAxes: [{
                gridLines: {
                    color: "rgba(204, 204, 204,0.1)"
                }
            }]
        },
        legend: {
            display: false
        },
        elements: {
            point: {
                radius: 3
            }
        }
    }

    const polarOptions = {
        scaleShowLabels: false,
        scales: {
            yAxes: [{
                ticks: {
                    display: false //this will remove only the label
                }
            }],
            xAxes: [{
                ticks: {
                    display: false //this will remove only the label
                }
            }]
        }

    }

    return (<>
        <div className="col-md-4 grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Total Fuel Savings (Kg)</h4>
                    <Line data={areaData} options={areaOptions} />
                </div>
            </div>
        </div>
        <div className="col-md-4 grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Average Fuel Savings (%)</h4>
                    <Bar data={barData} options={options} />
                </div>
            </div>
        </div>
        <div className="col-md-4 grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Total Carbon Emission Savings (Kg)</h4>
                    <Line data={lineData} options={lineOptions} />
                </div>
            </div>
        </div>

    </>);
}
import React from 'react';
import './css/LoadingScreen.css';

const LoadingScreen = ({ isLoading }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <div className="loading-screen">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading...</p>
        </div>
    );
};

export default LoadingScreen;
import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';
import { Accordion, Card } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { SwitchToggle } from "../usermanagement/SwitchToggle";
import { deleteFromGroup, getAllGroups, addToGroup, getAirlineRoles } from "../../graph";
import 'ag-grid-community/dist/styles/ag-grid.css';
import "../aggrid.css";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay.jsx";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AirlineTSPCellRender } from "./AirlineTSPCellRenderer";
import { IsContractCellRenderer } from "./IsContractCellRenderer";
import { IsActiveCellRenderer } from "./IsActiveCellRenderer";
import { SwitchToggleOnboarding } from "../airlinemanagement/SwitchToggleOnboarding";
import MenuItem from '@mui/material/MenuItem';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment'
import { Chip, FormHelperText, Input } from "@mui/material";
import { FleetCellRenderer } from "./FleetCellRenderer";
import { pink } from '@mui/material/colors';
import Box from '@mui/material/Box';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import AirlinesIcon from '@mui/icons-material/Airlines';
import { Airlines } from "@mui/icons-material";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import EventIcon from '@mui/icons-material/Event';
import TodayIcon from '@mui/icons-material/Today';

const axios = require('axios');

export const ListFleets = (props) => {
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [token, setToken] = useState();
    const [loader2, setLoader2] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [deleteLabel, setDeleteLabel] = useState("Delete");
    const [createLabel, setCreateLabel] = useState("Create Fleet");
    const [deleteUsersLabel, setDeleteUsersLabel] = useState("Create Fleet");
    const [statusText, setStatusText] = useState();
    const [showAlertText, setShowAlertText] = useState("Error unknown");
    const [showSuccessText, setShowSuccessText] = useState();
    const [showDeleteProgressBar, setShowDeleteProgressBar] = useState(false);
    const [deleteProgressBarCount, setDeleteProgressBarCount] = useState(0);
    const [disableDeleteButton, setDisableDeleteButton] = useState(false);
    const [count, setCount] = useState(0);
    const [filterVal, setFilterVal] = useState();
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const [exportDisabled, setExportDisabled] = useState(true);
    const [cancelButtonEnabled, setCancelButtonEnabled] = useState(false);
    const [titleWarning, setTitleWarning] = useState();
    const [closeDisabled, setCloseDisable] = useState(false);
    const [TSP, setTSP] = useState();
    const abort = useRef(0);
    const [isError, setIsError] = useState(false);
    const [roles, setRoles] = useState([]);
    const [resetSwitch, setResetSwitch] = useState(false);
    const [nextReportDue, setNextReportDue] = useState(moment().format());
    const [expirationDate, setExpirationDate] = useState(moment().format());
    const [trialStartDate, setTrialStartDate] = useState(moment().format());
    const [formError, setFormError] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [airlines, setAirlines] = useState([]);
    const [chipValues, setChipValues] = useState([]);
    const [currValue, setCurrValue] = useState("");
    const [daysBeforeDue3, setDaysBeforeDue3] = useState();
    const [daysBeforeDue7, setDaysBeforeDue7] = useState();
    const [daysBeforeDue14, setDaysBeforeDue14] = useState();


    useEffect(() => {
        getFleets();
        getTSPAF();
        getAirlines();
    }, []);

    const handleClose = () => {
        setShow(false);
        setShowAlert(false);
        setShowAdd(false);
    }

    const models = [
        { "key": "737", "value": "737" },
        { "key": "747", "value": "747" },
        { "key": "757", "value": "757" },
        { "key": "767", "value": "767" },
        { "key": "777", "value": "777" },
        { "key": "787", "value": "787" }
    ];

    const dataSources = [
        { "key": "MAESTRO/MFTS", "value": "MAESTRO/MFTS" },
        { "key": "Message Courier", "value": "Message Courier" },
        { "key": "Cloud Drive", "value": "Cloud Drive" },
        { "key": "AID/SUPA", "value": "AID/SUPA" },
        { "key": "Flight Data Analyics/MFTS", "value": "Flight Data Analyics/MFTS" },
        { "key": "AHM", "value": "AHM" },
        { "key": "AHM/MAESTRO", "value": "AHM/MAESTRO" },
        { "key": "FTP", "value": "FTP" },
        { "key": "Bifrost", "value": "Bifrost" },
        { "key": "Other", "value": "Other" }
    ];

    const deployments = [
        { "key": "Apple Store", "value": "Apple Store", "label": "Public app store (Apple Store)" },
        { "key": "ABM, MDM", "value": "ABM, MDM", "label": "Private app delivery (ABM, MDM)", },

    ];

    const getAirlines = () => {
        let airlines = [];
        getAllGroups(props.token).then(response => {
            response.value.forEach(group => {
                if (group.displayName.startsWith("airline") == true) {
                    airlines.push(group.displayName.replace("airline-", "").toUpperCase());
                }
            });
            airlines.sort();
            setAirlines(airlines);
        });
    }

    const gridOptions = {
        pagination: true,
        onCellEditingStopped: async function (event) {
            console.log("ask the server to update changed data in back end", event);
            let data = { rowIndex: event.rowIndex, id: event.data.userId, col: event.column.colId, value: event.value }
            let status = await editUser(event.data);

            if (status == 204) {
                let oldGroupId = null;
                let newGroupId = null;
                if (event.oldValue.includes("role-")) {
                    if (event.oldValue != event.newValue) {
                        getAllGroups(props.token).then(response => {
                            response.value.forEach(group => {
                                if (group.displayName.includes(event.oldValue) == true) {
                                    oldGroupId = group.id;
                                }
                                if (group.displayName.includes(event.newValue) == true) {
                                    newGroupId = group.id;
                                }

                            });
                            deleteFromGroup(props.token, oldGroupId, event.data.objectId).then(response => {
                                console.log(response);
                            });
                            addToGroup(props.token, newGroupId, event.data.objectId).then(response => {
                                console.log(response);
                            });
                        });
                    }
                }

                //var column = event.column.colDef.field;
                //event.colDef.cellStyle = { 'color': 'green' };
                event.api.flashCells({
                    force: true,
                    columns: [event.column.colId],
                    rowNodes: [event.node]

                });
            }
            else {
                alert("Error updating user.");
                event.colDef.cellStyle = {
                    'background-color': 'red', 'transition': 'background-color 0.5s'
                };
                event.api.refreshCells({
                    force: true,
                    columns: [event.column.colId],
                    rowNodes: [event.node]

                });

                setTimeout(() => {
                    event.colDef.cellStyle = { 'background-color': 'transparent', 'transition': 'background-color 0.5s' };
                    event.api.refreshCells({
                        force: true,
                        columns: [event.column.colId],
                        rowNodes: [event.node]

                    });
                }, 500);
            }
        }

    }

    const editUser = async (data) => {

        const headers = new Headers();
        const bearer = `Bearer ${props.token}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        headers.append("Airline", `airline-${props.airline}`);

        headers.append("Ocp-Apim-Subscription-Key", `${process.env.REACT_APP_APIM_KEY}`);

        var body = JSON.stringify({
            "objectId": `${data.objectId}`,
            "displayName": `${data.displayName}`,
            "givenName": `${data.givenName}`,
            "surname": `${data.surname}`,
            "userRole": `${data.userRole}`,
            "mailNickname": `${data.mailNickname}`,
            "otherMails": [
                `${data.otherMails}`
            ],
            "userRole": `${data.userRole}`,
            "createdDateTime": `${data.createdDateTime}`,

        });

        const options = {
            method: "PUT",
            headers: headers,
            body: body
        };

        let res = await fetch(process.env.REACT_APP_USERS_EDIT_URI, options);
        let message = await res.text();
        let status = res.status;
        return status;
    }

    const onGridReady = params => {
        params.api.showLoadingOverlay();
    };

    /**
     * Auto-size all columns once the initial data is rendered.
     */
    const renderTSPs = async () => {
        try {
            gridRef.current.api.forEachNode(async node => {
                //var rowNode = gridRef.current.api.getRowNode(node.data.FleetID);
                const TSP = await getTSPs(node.data.Airline.toLowerCase());
                node.setDataValue('TSPVersion', TSP);
                console.log()
            });
        }
        catch (e) {
            console.log(e);
        }

    };

    const handleShow = e => {

        let users = [];
        var x = "<br></br>";
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        const deleteUsers = selectedData.map(node => {
            users.push(node);
        });
        setLoader2(" (Better know what you're doing!)");
        setTitleWarning(" <i class='mdi mdi-alert-octagon text-danger'></i>");
        setDisableDeleteButton(false);
        setStatusText();
        if (deleteUsers.length != 0) {
            setShow(true);
            users.forEach(user => {
                x = x + '<i class="mdi mdi-account-minus text-danger"></i> ' + user.givenName + ' ' + user.surname + ' with ID [<a class="text-danger">' + user.mailNickname + '</a>] </br>'

            });
            setDeleteUsersLabel(`You are about to delete: ${x}`);
        }
        else {
            alert("No users selected!")
        }

    };

    const deleteHandler = async (e) => {
        e.preventDefault();
        setDisableDeleteButton(true);
        setShowDeleteProgressBar(true)
        setDeleteUsersLabel("Deleting Users...");
        setDeleteProgressBarCount(0);
        setTitleWarning();
        setDeleteLabel(<><Spinner animation="border" size="sm" /></>);
        setLoader2(<><Spinner animation="border" size="sm" /></>);
        setCloseDisable(true);
        let selected = [];
        const selectedNodes = gridRef.current.api.getSelectedNodes()
        const selectedData = selectedNodes.map(node => node.data);
        //const objectId = selectedData.map(node => `${node.objectId}`).join(', ');

        let count = 0
        let increment = (100 / gridRef.current.api.getSelectedNodes().length);
        let countProgress = 0;

        let deleted = [];
        let notDeleted = [];
        var x = "";
        abort.current = 0;

        for (let node of selectedData) {
            if (abort.current == 0) {
                let status = await deleteUsers(node.objectId);
                console.log(status)
                countProgress = countProgress + increment;
                setDeleteProgressBarCount(countProgress);
                count++;
                try {
                    if (status == 204) {
                        deleted.push(node);
                        x = x + '<i class="mdi mdi-checkbox-marked-circle-outline text-success"></i> Deleted [' + node.mailNickname + '] successfully.</br>';
                        setDeleteUsersLabel(x)
                    }

                    else {
                        notDeleted.push(node);
                        x = x + '<i class="mdi mdi mdi-alert-circle text-danger"></i> Error deleting [' + node.mailNickname + '] -> ' + status.errorDescription + '</br>';
                        setDeleteUsersLabel(x);
                    }
                }
                catch (error) {
                    notDeleted.push(node);
                    x = x + '<i class="mdi mdi mdi-alert-circle text-danger"></i> Error deleting [' + node.mailNickname + '] -> ' + status + '</br>';
                    setDeleteUsersLabel(x);
                }

                if (count == gridRef.current.api.getSelectedNodes().length) {
                    getFleets();
                    setDeleteLabel("Delete");
                    setLoader2(": Done");
                    setCancelButtonEnabled(false);
                    //setStatusText(`${x} </br>`);
                    //setDeleteUsersLabel("Done");
                    let rows = gridRef.current.api.getDisplayedRowCount();
                    setCount(rows);
                    setTimeout(() => {
                        setShowDeleteProgressBar(false);
                        //setShow(false);
                        //setStatusText();

                    }, 1000);

                }
            }
            else {
                getFleets();
                setDeleteLabel("Delete");
                setLoader2(": Aborted");

                //setStatusText(`${x} </br>`);
                //setDeleteUsersLabel("Done");
                let rows = gridRef.current.api.getDisplayedRowCount();
                setCount(rows);
                setTimeout(() => {
                    setShowDeleteProgressBar(false);
                    //setShow(false);
                    //setStatusText();

                }, 1000);
                setCloseDisable(false);
                return;
            }
        }
        setCloseDisable(false)
    }

    const deleteUsers = async (user) => {
        const headers = new Headers();
        const bearer = `Bearer ${props.token}`;

        headers.append("Authorization", bearer);
        headers.append("User", user);
        headers.append("Membership", `airline-${props.airline}`);
        headers.append("Role", `role-${props.role}`);
        headers.append("ObjectID", `${props.graphData.id}`);
        headers.append("Ocp-Apim-Subscription-Key", `${process.env.REACT_APP_APIM_KEY}`);

        const options = {
            method: "DELETE",
            headers: headers,
        };

        let res = await fetch(`${process.env.REACT_APP_USERS_DELETE_URI}/${user}`, options);
        let status = await res.status;
        try {
            if (status == 204) {
                return status;
            }
            else {
                let json = await res.json();
                return json;
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const getFleets = async () => {
        const code = process.env.REACT_APP_FUNCTION_FLEETS_GET_CODE;

        var requestOptions = {
            method: "GET",
            headers: {
                "x-functions-key": `${code}`
            }
        };
        try {
            let res = await fetch(`${process.env.REACT_APP_FUNCTION_FLEETS_GET_URI}`, requestOptions);
            let status = await res.status;
            let data = await res.json();
            if (status == 200) {
                setRowData(data);
                let rows = gridRef.current.api.getDisplayedRowCount();
                setCount(rows);
                gridRef.current.api.hideOverlay();
            }
        }
        catch (error) {
            console.log(error);
        }

        renderTSPs();

    }

    //Create Fleets
    const [fleetName, setFleetName] = useState(null),
        onInputFleetName = ({ target: { value } }) => {
            setFleetName(value);
        };

    const [modelID, setModelID] = useState(null),
        onInputModelID = ({ target: { value } }) => {
            setModelID(value);
        };

    const [defaultCI, setDefaultCI] = useState(null),
        onInputDefaultCI = ({ target: { value } }) => {
            setDefaultCI(value);
        }

    const [dataSource, setDataSource] = useState(null),
        onInputDataSource = ({ target: { value } }) => {
            setDataSource(value);
        }

    const [dataReceived, setDataReceived] = useState(false),
        onInputDataReceived = ({ target: { value } }) => {
            setDataReceived(value);
        }

    const [deployment, setDeployment] = useState(null),
        onInputDeployment = ({ target: { value } }) => {
            setDeployment(value);
        }

    const [action, setAction] = useState(null),
        onInputAction = ({ target: { value } }) => {
            setAction(value);
        }

    const [contractID, setContractID] = useState(null),
        onInputContractID = ({ target: { value } }) => {
            setContractID(value);
        }

    const [reportingSchedule, setReportingSchedule] = useState(30),
        onInputReportingSchedule = ({ target: { value } }) => {
            setReportingSchedule(value);
        }

    const [ICAO, setICAO] = useState(null),
        onInputICAO = ({ target: { value } }) => {
            setICAO(value);
        }

    const onFormSubmit = e => {
        e.preventDefault();
        if ((modelID !== null)
            && (dataSource !== null)
            && (dataReceived !== null)
            && (defaultCI !== null)
            && (ICAO !== null)) {
            createFleet();
        }
        else {
            setFormError(true);
            alert("Error in form");
        }
    }

    const createFleet = async () => {

        setShowSuccess(false);
        setShowAlert(false)
        setShowAlertText();
        setCreateLabel(<><Spinner animation="border" size="sm" /></>)

        const headers = new Headers();
        const code = process.env.REACT_APP_FUNCTION_FLEETS_INSERT_CODE;

        headers.append("x-functions-key", code);
        headers.append("Content-Type", "application/json");

        var data = JSON.stringify({
            "FleetName": `${ICAO} ${modelID} Fleet`,
            "Airline": `${ICAO}`,
            "Model": `${modelID}`,
            "DefaultCI": `${defaultCI}`,
            "DataSource": `${dataSource}`,
            "DataReceived": `${dataReceived}`,
            "Frequency": `${reportingSchedule}`,
            "ExpirationDateAsString": `${expirationDate}`,
            "TrialStartDate": `${trialStartDate}`,
            "Deployment": `${deployment}`,
            "ReminderEmails": `${chipValues}`,
            "CreatedBy": `${props.graphData.userPrincipalName}`,
        });

        const options = {
            method: "POST",
            headers: headers,
            body: data
        };

        try {
            const res = await fetch(process.env.REACT_APP_FUNCTION_FLEETS_INSERT_URI, options);
            const responseStatus = await res.status;
            var responseData = null;

            if (responseStatus === 200) {
                setFormError(false);
                responseData = await res.json();
                setShowAdd(false);
                getFleets();
                //x
                setShowSuccess(true);
                setShowSuccessText(responseData.FleetName);
                setCreateLabel("Create Fleet");
                setIsError(false);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 10000);
            }
            else {
                responseData = await res.text();
                setShowAlert(true);
                setShowAlertText(responseData);
                setCreateLabel("Create Fleet");
                setIsError(true);
            }
        }
        catch (e) {
            console.log(e);
        }

    }

    const onFilterTextBoxChanged = e => {
        //gridRef.current.api.showNoRowsOverlay();
        setFilterVal(e.target.value)
        gridRef.current.api.setQuickFilter(e.target.value);
        let rows = gridRef.current.api.getDisplayedRowCount();
        setCount(rows);
        setResetSwitch(true);
    }

    const accountStateFormatter = (params) => {
        if (params.data.accountEnabled == "false") {
            return "PENDING";
        }
        else return "REGISTERED";
    };

    const roleFormatter = (params) => {
        return params.data.userRole.replace("role-", "")
    };

    const rowClassRules = {
        'row-activated': function (params) { return params.data.accountEnabled == "true"; },
        //'row-pending': function (params) { return params.data.accountEnabled == "false"; }
    };

    const cellClassRules = {
        'text-success': function (params) { return params.data.accountEnabled == "true"; },
        'text-warning': function (params) { return params.data.accountEnabled == "false"; }
    };

    const onRowSelected = useCallback((event) => {
        let count = gridRef.current.api.getSelectedNodes().length;
        if (count == 0) {
            setDeleteDisabled(true);
            setExportDisabled(true);
        }
        else {
            setDeleteDisabled(false);
            setExportDisabled(false);
        }
    }, []);

    const setAutoHeight = useCallback(() => {
        gridRef.current.api.setDomLayout('autoHeight');
        // auto height will get the grid to fill the height of the contents,
        // so the grid div should have no height set, the height is dynamic.
        document.querySelector('#myGrid').style.height = '';
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return CustomNoRowsOverlay;
    }, []);

    const onFilterChanged = () => {
        let rows = gridRef.current.api.getDisplayedRowCount();
        setCount(rows);
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            onlySelected: true,
        });
    }, []);

    const getTSPAF = async () => {
        const airline = props.airline.replace("airline-", "");
        const code = process.env.REACT_APP_FUNCTION_TSP_GET_CODE;
        fetch(`${process.env.REACT_APP_FUNCTION_TSP_GET_URI}?code=${code}&airline=${airline}`)
            .then(response => response.text())
            .then(data => {
                if (data != "") {

                    setTSP(data);
                }
                else {
                    setTSP("TSP Not Found");
                }
            }
            )
            .catch(error => console.log('error', error));
    }

    const getTSPs = async (airline) => {
        const code = process.env.REACT_APP_FUNCTION_TSP_GET_CODE;
        try {
            const res = await fetch(`${process.env.REACT_APP_FUNCTION_TSP_GET_URI}?code=${code}&airline=${airline}`)
            const status = res.status;
            if (status === 200) {
                return res.text();
            }
            else return "TSP Not Found"
        }
        catch (e) {
            console.log(e);
        }
    }

    const refreshGrid = useCallback(async () => {
        gridRef.current.api.showLoadingOverlay();
        await getFleets();
    }, []);

    const handleTrialStartDate = (newValue) => {
        setTrialStartDate(moment(newValue).format("YYYY-MM-DD"));
    };

    const handleExpirationDate = (newValue) => {
        setExpirationDate(moment(newValue).format("YYYY-MM-DD"));
    };

    //Chip handling for emails
    const handleKeyUp = (e) => {
        if (e.keyCode == 32 || e.keyCode == 13) {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(e.target.value.replaceAll(' ', ''))) {
                setChipValues((oldState) => [...oldState, e.target.value.replaceAll(' ', '')]);
                setCurrValue("");
            }
            else {
                alert("Invalid email format");
                setCurrValue("");
            }
        }

    };

    const handleChipChange = (e) => {
        setCurrValue(e.target.value);
    };

    const handleChipDelete = (item, index) => {
        let arr = [...chipValues];
        arr.splice(index, 1);
        console.log(item);
        setChipValues(arr);
    };

    const handleDaysBeforeDue3 = (e) => {
        setDaysBeforeDue3(e.target.checked);
    };
    const handleDaysBeforeDue7 = (e) => {
        setDaysBeforeDue7(e.target.checked);
    };
    const handleDaysBeforeDue14 = (e) => {
        setDaysBeforeDue14(e.target.checked);
    };

    const sleep = async (time) => {
        return new Promise(res => setTimeout(res, time));
    }

    const refresh = async () => {
        await sleep(1000);
        refreshGrid();
    }

    return (
        <div>
            <div className="row">
                <div className="col-sm-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <Accordion >
                                <Card>
                                    <Card.Header style={{ backgroundColor: "#191c24" }}>
                                        <Accordion.Toggle as={Button}
                                            variant="link" eventKey="0">
                                            <h4> <i className="mdi mdi-chevron-double-down icon-md"></i>Create Fleet</h4>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body style={{  }}>
                                            <ThemeProvider theme={props.theme}>
                                                <CssBaseline />
                                                <div className="col-12 grid-margin" >
                                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <Airlines sx={{ mr: 1, my: 0.5 }} />
                                                        <div style={{ paddingTop: 5 }}>
                                                            Fleet Information
                                                        </div>
                                                    </Box>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <TextField
                                                                required
                                                                select
                                                                fullWidth
                                                                id="icao"
                                                                label="ICAO"
                                                                type="search"
                                                                size="small"
                                                                variant="standard"
                                                                onChange={onInputICAO}
                                                                value={ICAO}
                                                                helperText="Required"
                                                                error={formError}

                                                            >
                                                                {
                                                                    airlines.map((airline) => {
                                                                        return (
                                                                            <MenuItem key={airline} value={airline}>
                                                                                {airline}
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                                }

                                                            </TextField>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                required
                                                                select
                                                                fullWidth
                                                                id="fleet"
                                                                label="Fleet"
                                                                type="search"
                                                                size="small"
                                                                variant="standard"
                                                                onChange={onInputModelID}
                                                                value={modelID}
                                                                helperText="Required"
                                                                error={formError}

                                                            >
                                                                {models.map((option) => (
                                                                    <MenuItem key={option.key} value={option.key}>
                                                                        {option.value}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </div>
                                                        {/*<div className="col-md-3">
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="name"
                                                                label="Fleet Name"
                                                                type="search"
                                                                size="small"
                                                                variant="standard"
                                                                onChange={onInputFleetName}
                                                                value={fleetName}
                                                                helperText="Required"
                                                                error={formError}
                                                            />
                                                        </div>*/}
                                                        <div className="col-md-3">
                                                            <TextField
                                                                required
                                                                select
                                                                fullWidth
                                                                id="dataSource"
                                                                label="Data Source"
                                                                type="search"
                                                                size="small"
                                                                variant="standard"
                                                                onChange={onInputDataSource}
                                                                value={dataSource}
                                                                helperText="Required"
                                                                error={formError}
                                                            >
                                                                {dataSources.map((option) => (
                                                                    <MenuItem key={option.key} value={option.key}>
                                                                        {option.value}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="defaultCI"
                                                                label="Default CI"
                                                                type="number"
                                                                size="small"
                                                                variant="standard"
                                                                onChange={onInputDefaultCI}
                                                                value={defaultCI}
                                                                helperText="Required"
                                                                error={formError}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <div className="row">

                                                        {/*<div className="col-md-3">
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <MobileDateTimePicker
                                                                    label="Next Report Due"
                                                                    inputFormat="MM/DD/YYYY HH:MM"
                                                                    value={nextReportDue}
                                                                    onChange={handleNextReportDue}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    size="small"
                                                                />
                                                            </LocalizationProvider>
                                                            </div>*/}


                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <TextField
                                                                required
                                                                select
                                                                fullWidth
                                                                id="deployment"
                                                                label="Deployment"
                                                                type="search"
                                                                size="small"
                                                                variant="standard"
                                                                onChange={onInputDeployment}
                                                                value={deployment}
                                                                helperText="Required"
                                                                error={formError}
                                                            >
                                                                {deployments.map((option) => (
                                                                    <MenuItem key={option.key} value={option.key}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                          
                                                        </div>
                                                        <div className="col-md-3">
                                                            <TextField
                                                                id="dataReceived"
                                                                select
                                                                fullWidth
                                                                label="Data Received *"
                                                                defaultValue={0}
                                                                helperText="Required"
                                                                variant="standard"
                                                                value={dataReceived}
                                                                onChange={onInputDataReceived}
                                                                error={formError}
                                                            >
                                                                <MenuItem key="false" value={false}>
                                                                    {"No"}
                                                                </MenuItem>
                                                                <MenuItem key="true" value={true}>
                                                                    {"Yes"}
                                                                </MenuItem>
                                                            </TextField>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterMoment} >
                                                                <MobileDatePicker
                                                                    label="Contract Expiration Date"
                                                                    inputFormat="MMMM Do YYYY"
                                                                    value={expirationDate}
                                                                    onChange={handleExpirationDate}
                                                                    renderInput={(params) =>
                                                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                            <EventIcon sx={{ mr: 1, my: 0.5 }} />
                                                                            <TextField variant="standard" {...params} />
                                                                        </Box>
                                                                    }
                                                                    size="small"
                                                                    variant="standard"
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterMoment} >
                                                                <MobileDatePicker
                                                                    label="Trial Start Date"
                                                                    inputFormat="MMMM Do YYYY"
                                                                    value={trialStartDate}
                                                                    onChange={handleTrialStartDate}
                                                                    renderInput={(params) =>
                                                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                            <TodayIcon sx={{ mr: 1, my: 0.5 }} />
                                                                            <TextField variant="standard" {...params} />
                                                                        </Box>
                                                                    }
                                                                    size="small"
                                                                    variant="standard"
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                        <br></br>
                                                    </div>
                                                    
                                                    <br></br>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <button style={{ borderRadius: 1 }} type="submit" disabled={false} size="sm" className="btn btn-primary btn-lg btn-block" onClick={onFormSubmit}>
                                                                {createLabel}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <Collapse in={showAlert}>
                                                        <Alert style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} severity="error" onClose={() => { setShowAlert(false) }}>Conflict! [{showAlertText}]</Alert>
                                                    </Collapse>
                                                    <Collapse in={showSuccess}>
                                                        <Alert style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} onClose={() => { setShowSuccess(false) }}>Fleet [{showSuccessText}] created successfully!</Alert>
                                                    </Collapse>
                                                </div>
                                            </ThemeProvider>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 grid-margin">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <h4 className="card-title">Fleets</h4>

                                        </div>
                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text bg-primary text-white">
                                                        <i className="mdi mdi-account-search"></i>
                                                    </span>
                                                </div>
                                                <input value={filterVal} aria-label="" style={{ color: "#fff" }} placeholder="Search..." type="text" className="form-control form-control"
                                                    onChange={onFilterTextBoxChanged}
                                                />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 grid-margin" style={{ marginBottom: 0 }}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <p className="card-description"> Group Membership <code className="text-warning">{props.airline.toUpperCase()}</code></p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 align-self-center d-flex align-items-center justify-content-center">
                                        <div className="row">
                                            <SwitchToggleOnboarding values={["DISABLED", "all", "ACTIVE"]} selected="all" gridRef={gridRef} setCount={setCount} setFilterVal={setFilterVal} resetSwitch={resetSwitch} setResetSwitch={setResetSwitch} />
                                        </div>
                                        <div className="row" >
                                            <div className="col-12 ">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 grid-margin" style={{ marginTop: 0 }}>
                                <div className="row">
                                    <div className="col-md-4">

                                    </div>
                                    <div className="col-md-4 align-self-center d-flex align-items-center justify-content-center">

                                        <div className="">
                                            <div className="col-12 ">
                                                <h4><i className='text-primary'>{count}</i></h4>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                </div>
                            </div>

                            <div className="ag-theme-alpine-dark" style={{ width: '100%', height: 550, marginTop: -15, marginBottom: 20 }}>
                                <Button className="btn-primary-override" variant="primary" style={{ borderRadius: 1, marginLeft: 3, fontWeight: "bold", borderColor: "transparent", backgroundColor: "transparent", color: "#777" }} size="md" onClick={e => {
                                    refreshGrid()

                                }}>
                                    <i className="mdi mdi-refresh text-success mdi-18px"></i>Refresh
                                </Button>
                                {/*
                                    deleteDisabled
                                        ?
                                        <></>
                                        :
                                        <Button className="btn-primary-override" variant="danger" disabled={false} style={{ borderRadius: 1, marginLeft: 3, fontWeight: "bold", borderColor: "transparent", backgroundColor: "transparent", color: "#777" }} size="md" onClick={e => {
                                            handleShow();
                                            setCancelButtonEnabled(false);
                                        }}><i className="mdi mdi-delete-forever text-danger mdi-18px"></i>{deleteLabel}</Button>

                                    */}
                                {
                                    exportDisabled
                                        ?
                                        <></>
                                        :
                                        <Button className="btn-primary-override" variant="primary" disabled={false} style={{ borderRadius: 1, marginLeft: 3, fontWeight: "bold", borderColor: "transparent", backgroundColor: "transparent", color: "#777" }} size="md" onClick={e => {
                                            onBtnExport();
                                        }}><i className="mdi mdi-file-export text-primary mdi-18px"></i>Export</Button>
                                }

                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    rowSelection="multiple"
                                    defaultColDef={{ resizable: true, editable: true, cellEditorPopup: false }}
                                    onGridReady={onGridReady}
                                    onFirstDataRendered={renderTSPs}
                                    enableCellTextSelection={false}
                                    gridOptions={gridOptions}
                                    animateRows={true}
                                    rowClassRules={rowClassRules}
                                    onRowSelected={onRowSelected}
                                    onFilterChanged={onFilterChanged}
                                    selectionChanged={e => console.log(e)}
                                    stopEditingWhenCellsLoseFocus={true}
                                    multiSortKey={'ctrl'}
                                    enableBrowserTooltips={true}
                                    suppressExcelExport={true}
                                    context={{ refresh }}
                                >
                                    <AgGridColumn field="FleetID" sortable={true} filter={true} hide={true} editable={false}></AgGridColumn>
                                    <AgGridColumn field="AirlineName" sortable={true} filter={true} headerName={"Airline"} cellRenderer={FleetCellRenderer} cellRendererParams={{
                                        theme: props.theme,
                                        models: models,
                                        dataSources: dataSources,
                                        deployments: deployments,
                                        user: props.graphData.userPrincipalName
                                    }} editable={false} checkboxSelection={true} headerCheckboxSelection={true} headerCheckboxSelectionFilteredOnly={true}></AgGridColumn>
                                    <AgGridColumn field="Airline" sortable={true} filter={true} hide={false} editable={false} headerName={"ICAO"}></AgGridColumn>
                                    <AgGridColumn field="Model" sortable={true} filter={true} headerName={"Fleet"}></AgGridColumn>
                                    <AgGridColumn field="FleetName" sortable={true} filter={true} editable={true}></AgGridColumn>
                                    <AgGridColumn field="DefaultCI" sortable={true} filter={true} hide={true}></AgGridColumn>
                                    <AgGridColumn field="DataSource" sortable={true} filter={true} editable={false} hide={true}></AgGridColumn>
                                    <AgGridColumn field="DataReceived" sortable={true} filter={false} hide={true} editable={false}></AgGridColumn>
                                    <AgGridColumn field="TSPVersion" sortable={true} editable={false} hide={true}></AgGridColumn>
                                    <AgGridColumn field="Frequency" sortable={true} filter={true} editable={false} hide={true} headerName={"Frequency (Weeks)"}></AgGridColumn>
                                    <AgGridColumn field="LastSentAsString" sortable={true} editable={false} hide={true}></AgGridColumn>
                                    <AgGridColumn field="ExpirationDateAsString" sortable={true} filter={true} editable={false} hide={true} width={280} valueGetter={params => {
                                        return moment(params.data.ExpirationDateAsString).format('MMMM Do YYYY, h:mm a');
                                    }}></AgGridColumn>
                                    <AgGridColumn field="Deployment" sortable={true} filter={true} editable={false} hide={true}></AgGridColumn>
                                    <AgGridColumn field="ReminderEmails" sortable={true} filter={true} hide={true}></AgGridColumn>
                                    <AgGridColumn field="DaysBeforeDue3" sortable={true} filter={true} hide={true}></AgGridColumn>
                                    <AgGridColumn field="DaysBeforeDue7" sortable={true} filter={true} hide={true}></AgGridColumn>
                                    <AgGridColumn field="DaysBeforeDue14" sortable={true} filter={true} hide={true}></AgGridColumn>
                                    <AgGridColumn field="TrialStartDate" sortable={true} filter={true} hide={true} width={280} valueGetter={params => {
                                        return moment(params.data.TrialStartDate).format('MMMM Do YYYY');
                                    }}></AgGridColumn>
                                    <AgGridColumn field="IsContract" sortable={true} filter={true} cellRenderer={IsContractCellRenderer}></AgGridColumn>
                                    <AgGridColumn field="IsActive" sortable={true} filter={true} cellRenderer={IsActiveCellRenderer} cellEditor="agSelectCellEditor" cellEditorParams={{
                                        values: roles,
                                    }} valueGetter={params => {
                                        if (params.data.IsActive === false) {
                                            return "DISABLED"
                                        }
                                        else return "ACTIVE";
                                    }}>
                                    </AgGridColumn>
                                    <AgGridColumn field="ContractID" sortable={true} filter={true} hide={true}></AgGridColumn>
                                    <AgGridColumn field="ReportReminderID" sortable={true} filter={true} hide={true}></AgGridColumn>
                                </AgGridReact>

                                <Modal scrollable="true" show={show} onHide={handleClose} contentClassName={"modal"}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete Users {loader2}
                                            <a dangerouslySetInnerHTML={{ __html: titleWarning }} />
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div dangerouslySetInnerHTML={{ __html: deleteUsersLabel }} />
                                    </Modal.Body>
                                    {
                                        showDeleteProgressBar
                                            ?
                                            <ProgressBar variant="danger" animated now={deleteProgressBarCount} />
                                            :
                                            <></>
                                    }
                                    <div dangerouslySetInnerHTML={{ __html: statusText }} />

                                    <Modal.Footer>
                                        {
                                            cancelButtonEnabled
                                                ?
                                                <Button variant="warning" size="sm" onClick={e => {
                                                    abort.current = 1;
                                                    setCancelButtonEnabled(false);
                                                    //setGridDeleteButtonEnabled(true);
                                                }
                                                }>
                                                    Abort
                                                </Button>
                                                :
                                                <></>
                                        }
                                        <Button variant="secondary" disabled={closeDisabled} size="sm" onClick={e => {
                                            handleClose();
                                            setCancelButtonEnabled(false);

                                        }}>
                                            Close
                                        </Button>

                                        <Button variant="danger" size="sm" onClick={e => {
                                            deleteHandler(e);
                                            setCancelButtonEnabled(true);
                                        }} disabled={disableDeleteButton}>
                                            {deleteLabel}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};



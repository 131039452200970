import React, { useState } from "react";

export const IsActiveCellRenderer = (props) => {



  return (

    <>
      {
        props.data.IsActive == false
          ?
          <div className='text-warning'>DISABLED</div>
          :
          <div className='text-success'>ACTIVE </div>
      }

    </>


  );

}
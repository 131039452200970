import React, { Component, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import { WidthFull } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid, GridColDef, GridValueGetterParams, GridCellEditStopReasons } from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { right } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import { useSnackbar } from 'notistack';

function TabPanelVertical(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export const ModifyKeys = (props) => {

    const [valueVerticalTab, setValueVerticalTab] = React.useState(0);
    const [rowData, setRowData] = useState([]);
    const { v4: uuidv4 } = require('uuid');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleVerticalTab = (event, newValue) => {
        setValueVerticalTab(newValue);
    };

    useEffect(() => {
        getPreferences();
    }, [])

    const getPreferences = async () => {

        const headers = new Headers();

        const options = {
            method: "GET",
            headers: headers
        };

        const code = process.env.REACT_APP_FUNCTION_USER_PREFERENCES_GET_CODE;
        fetch(`${process.env.REACT_APP_FUNCTION_USER_PREFERENCES_GET_URI}?code=${code}&airline=airline-${props.airline}`, options)
            .then(response => response.json())
            .then(data => {
                setRowData(data);
            }
            )
            .catch(error => console.log('error', error));
    }


    const insertUserPref = async () => {
        const headers = new Headers();
        const code = process.env.REACT_APP_FUNCTION_USER_PREFS_INSERT_CODE;

        headers.append("x-functions-key", code);
        headers.append("Content-type", "application/json");

        var body = JSON.stringify([{
            "preference": `${preference}`,
            "userKey": `${userKey}`,
            "enabled": `${enabled}`,
            "description": `${description}`,
            "toggle": `${toggle}`,
            "value": `${value}`,
            "groupBy": `${groupBy}`,
        }]);

        const options = {
            method: "POST",
            headers: headers,
            body: body
        };

        try {
            let res = await fetch(`${process.env.REACT_APP_FUNCTION_USER_PREFS_INSERT_URI}?airline=airline-${props.airline}&updatedBy=${props.graphData.userPrincipalName.split('@')[0]}&fullArray=false`, options);
            let status = res.status;
            console.log(status);
            if (status == 200) {
                console.log("Rows updated: " + userKey);
                enqueueSnackbar(`Updated preference`, { variant: 'success' });
                getPreferences();
            }
            else {
                enqueueSnackbar("Error", { variant: 'error' });
                console.log('error');
            }

        }
        catch (e) {
            console.log(e);
            enqueueSnackbar("Error", { variant: 'error' });
            console.log('error', e);
        }
    }

    const columns = [
        { field: 'userKey', headerName: 'User Key', flex: 1, editable: false },
        { field: 'preference', headerName: 'Preference', flex: 1, editable: true },
        { field: 'description', headerName: 'Description', flex: 1, editable: true },
        {
            field: 'groupBy', headerName: 'Group by', flex: 1, editable: true, type: 'singleSelect',
            valueOptions: ['Units of Measurement Default Settings', 'Flight Progress Table Default Setting', 'Notification Trigger Default Settings', 'Suspension Default Settings']
        },
        { field: 'toggle', headerName: 'Toggle', width: 60, editable: true, type: 'singleSelect', valueOptions: ['true', 'false'] },
        { field: 'enabled', headerName: 'Enabled', width: 70, editable: true, type: 'singleSelect', valueOptions: ['true', 'false'] },
        { field: 'value', headerName: 'Value', flex: 1, editable: false },

    ];

    const rowIds = useRef([]);
    const onRowsSelectionHandler = (ids) => {
        rowIds.current = ids;
        console.log(rowIds.current.length)
    };

    const updateUserPreference = (updatedRow, originalRow) => {
        console.log(updatedRow)
        const headers = new Headers();

        var body = JSON.stringify({
            "id": `${updatedRow.id}`,
            "preference": `${updatedRow.preference}`,
            "userKey": `${updatedRow.userKey}`,
            "enabled": `${updatedRow.enabled}`,
            "description": `${updatedRow.description}`,
            "toggle": `${updatedRow.toggle}`,
            "value": `${updatedRow.value}`,
            "groupBy": `${updatedRow.groupBy}`,
            "airline": `${updatedRow.airline}`
        });

        const options = {
            method: "PUT",
            headers: headers,
            body: body
        };

        const code = process.env.REACT_APP_FUNCTION_USER_PREFERENCES_UPDATEMETADATA_CODE;
        fetch(`${process.env.REACT_APP_FUNCTION_USER_PREFERENCES_UPDATEMETADATA_URI}?code=${code}`, options)
            .then(response => response.text())
            .then(data => {
                console.log("Rows updated: " + data);
                enqueueSnackbar(`Updated preference`, { variant: 'success' });
            }
            )
            .catch(error => {
                enqueueSnackbar("Error", { variant: 'error' });
                console.log('error', error);
            });
    }

    //const [openDelete, setOpenDelete] = React.useState(false);
    const handleClickOpenDelete = () => {
        rowIds.current.forEach(id => {
            deleteUserPrefs(id);
        });
    };

    const deleteUserPrefs = async (id) => {
        const headers = new Headers();

        const options = {
            method: "DELETE",
            headers: headers,
        };

        const code = process.env.REACT_APP_FUNCTION_USER_PREFERENCES_DELETE_CODE;
        let res = await fetch(`${process.env.REACT_APP_FUNCTION_USER_PREFERENCES_DELETE_URI}?code=${code}&id=${id}`, options);
        let status = res.status;
        console.log(status);
        if (status == 204) {
            console.log("Row deleted: " + id);
            enqueueSnackbar(`Deleted preference`, { variant: 'success' });
            getPreferences();
        }
        else {
            enqueueSnackbar("Error", { variant: 'error' });
            console.log('error');
        }
    }

    const [preference, setPreference] = useState(),
        onInputPreference = ({ target: { value } }) => setPreference(value);
    const [userKey, setUserKey] = useState(),
        onInputUserKey = ({ target: { value } }) => setUserKey(value);
    const [description, setDescription] = useState(),
        onInputDescription = ({ target: { value } }) => setDescription(value);
    const [value, setValue] = useState(),
        onInputValue = ({ target: { value } }) => setValue(value);
    const [groupBy, setGroupBy] = useState(),
        onInputGroupBy = ({ target: { value } }) => setGroupBy(value);
    const [toggle, setToggle] = useState(), handleToggle = (e) => {
        setToggle(e.target.checked);
    };
    const [enabled, setEnabled] = useState(), handleEnabled = (e) => {
        setEnabled(e.target.checked);
    };

    return (
        <>
            <div className=''>
                <div className='row'>
                    <div className='col-sm-2'>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={valueVerticalTab}
                            onChange={handleVerticalTab}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab label="User Preferences" {...a11yProps(0)} />
                            <Tab label="Airline Preferences" {...a11yProps(1)} />
                            <Tab label="Feature Mgmt" {...a11yProps(2)} />
                        </Tabs>
                    </div>
                    <div className='col-sm-10'>
                        <TabPanelVertical
                            value={valueVerticalTab} index={0}>
                            <ThemeProvider theme={props.theme}>
                                <CssBaseline />
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-sm-2'>
                                            <TextField
                                                required
                                                fullWidth
                                                id="preference"
                                                label="Preference"
                                                type="search"
                                                size="small"
                                                variant="standard"
                                                onChange={onInputPreference}
                                                value={preference}
                                                helperText="Required"
                                            //error={formError}
                                            />
                                        </div>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required
                                                fullWidth
                                                id="userKey"
                                                label="User Key"
                                                type="search"
                                                size="small"
                                                variant="standard"
                                                onChange={onInputUserKey}
                                                value={userKey}
                                                helperText="Required"
                                            //error={formError}
                                            />
                                        </div>
                                        <div className='col-sm-2'>
                                            <TextField
                                                required
                                                select
                                                fullWidth
                                                id="groupBy"
                                                label="Group By"
                                                type="search"
                                                size="small"
                                                variant="standard"
                                                helperText="Required"
                                                onChange={onInputGroupBy}
                                                value={groupBy}
                                            >
                                                <MenuItem key="false" value={"Units of Measurement Default Settings"}>
                                                    {"Units of Measurement Default Settings"}
                                                </MenuItem>
                                                <MenuItem key="true" value={"Flight Progress Table Default Setting"}>
                                                    {"Flight Progress Table Default Setting"}
                                                </MenuItem>
                                                <MenuItem key="true" value={"Notification Trigger Default Settings"}>
                                                    {"Notification Trigger Default Settings"}
                                                </MenuItem>
                                                <MenuItem key="true" value={"Suspension Default Settings"}>
                                                    {"Suspension Default Settings"}
                                                </MenuItem>
                                            </TextField>
                                        </div>
                                        <div className='col-sm-2'>
                                            <TextField
                                                required
                                                fullWidth
                                                id="value"
                                                label="Value"
                                                type="search"
                                                size="small"
                                                variant="standard"
                                                onChange={onInputValue}
                                                value={value}
                                                helperText="Required"
                                            //error={formError}
                                            />
                                        </div>
                                        <div className='col-sm-2'>
                                            <TextField
                                                required
                                                fullWidth
                                                id="description"
                                                label="Description"
                                                type="search"
                                                size="small"
                                                variant="standard"
                                                onChange={onInputDescription}
                                                value={description}
                                                helperText="Required"
                                                multiline
                                                maxRows={3}
                                            //error={formError}
                                            />
                                        </div>
                                        <div className='col-sm-1'>
                                            <IconButton aria-label="delete" size="large" onClick={insertUserPref}>
                                                <SaveIcon fontSize="inherit" />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className='row'>
                                        <div className='col-sm-2'>
                                            <FormControlLabel
                                                value="top"
                                                control={<Checkbox
                                                    label="Toggle"
                                                    checked={toggle}
                                                    onChange={handleToggle}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />}
                                                label="Toggle"
                                                labelPlacement="left"
                                            />
                                        </div>
                                        <div className='col-sm-2'>
                                            <FormControlLabel
                                                value="top"
                                                control={<Checkbox
                                                    checked={enabled}
                                                    onChange={handleEnabled}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />}
                                                label="Enabled"
                                                labelPlacement="left"
                                            />
                                        </div>

                                    </div>
                                    <Divider></Divider>

                                    <br></br>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <h4>User Preferences</h4>

                                        </div>
                                        <div className='col-sm-6' >
                                            
                                                    <IconButton sx={{ marginTop: -3, float: right }} aria-label="delete" size="large" onClick={handleClickOpenDelete}>
                                                        <RemoveCircleIcon sx={{ color: 'red' }} fontSize="inherit" />
                                                    </IconButton>
                                                  
                                        </div>
                                    </div>
                                    <Divider></Divider>

                                    <div className='row'>
                                        <div style={{ height: "100%", flex: 1 }}>
                                            {
                                                rowData
                                                    ?
                                                    <DataGrid
                                                        rows={rowData}
                                                        columns={columns}
                                                        //getRowId={row => row.id}
                                                        initialState={{
                                                            pagination: {
                                                                paginationModel: { page: 0, pageSize: 5 },
                                                            },
                                                        }}
                                                        pageSizeOptions={[5, 10]}
                                                        checkboxSelection
                                                        sx={{ border: 0 }}
                                                        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                                        //editMode="row"
                                                        /*onCellEditStop={(params, event) => {
                                                            console.log('no funciona')
                                                            if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                                                              event.defaultMuiPrevented = true;
                                                              console.log(params)
                                                            }
                                                          }}*/
                                                        processRowUpdate={(updatedRow, originalRow) => {
                                                            updateUserPreference(updatedRow, originalRow);
                                                            return updatedRow;
                                                        }
                                                        }
                                                        onProcessRowUpdateError={e => console.log(e)}

                                                    />
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </ThemeProvider>
                        </TabPanelVertical>
                        <TabPanelVertical value={valueVerticalTab} index={1}>
                        </TabPanelVertical>
                        <TabPanelVertical value={valueVerticalTab} index={2}>
                        </TabPanelVertical>
                    </div>
                </div>
            </div>



        </>
    );
}
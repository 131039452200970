import "@patternfly/react-core/dist/styles/base.css";

import React, { useEffect } from 'react';
import { FileRejection } from 'react-dropzone';
import { MultipleFileUpload, MultipleFileUploadMain, MultipleFileUploadStatus, MultipleFileUploadStatusItem, Modal, Checkbox, DropEvent } from '@patternfly/react-core';
import UploadIcon from '@patternfly/react-icons/dist/esm/icons/upload-icon';
import { QarUploadStatus } from "./QarUploadStatus";

export const QarDropZone = (props) => {
    const [isHorizontal, setIsHorizontal] = React.useState(false);
    const [currentFiles, setCurrentFiles] = React.useState([]);
    const [readFileData, setReadFileData] = React.useState([]);
    const [showStatus, setShowStatus] = React.useState(false);
    const [statusIcon, setStatusIcon] = React.useState('inProgress');
    const [modalText, setModalText] = React.useState('');
    const [limit, setLimit] = React.useState(250);
    const [maxSize, setMaxSize] = React.useState(1073741824000);
    //const [disabled, setDisabled] = React.useState(true);

    if (!showStatus && currentFiles.length > 0) {
        setShowStatus(true);
    }

    useEffect(() => {
        if (readFileData.length < currentFiles.length) {
            setStatusIcon('inProgress');
        } else if (readFileData.every(file => file.loadResult === 'success')) {
            setStatusIcon('success');
        } else {
            setStatusIcon('danger');
        }
    }, [readFileData, currentFiles]);

    const removeFiles = namesOfFilesToRemove => {
        const newCurrentFiles = currentFiles.filter(currentFile => !namesOfFilesToRemove.some(fileName => fileName === currentFile.name));
        setCurrentFiles(newCurrentFiles);
        const newReadFiles = readFileData.filter(readFile => !namesOfFilesToRemove.some(fileName => fileName === readFile.fileName));
        setReadFileData(newReadFiles);
    };
    const handleFileDrop = (_event, droppedFiles) => {
        const currentFileNames = currentFiles.map(file => file.name);
        const reUploads = droppedFiles.filter(droppedFile => currentFileNames.includes(droppedFile.name));
        Promise.resolve().then(() => removeFiles(reUploads.map(file => file.name))).then(() => setCurrentFiles(prevFiles => [...prevFiles, ...droppedFiles]));
    };
    const handleReadSuccess = (data, file) => {
        setReadFileData(prevReadFiles => [...prevReadFiles, {
            data,
            fileName: file.name,
            loadResult: 'success'
        }]);
        props.setConfigFiles(currentFiles);
    };
    const handleReadFail = (error, file) => {
        setReadFileData(prevReadFiles => [...prevReadFiles, {
            loadError: error,
            fileName: file.name,
            loadResult: 'danger'
        }]);
    };
    const handleDropRejected = fileRejections => {
        if (fileRejections.length === 1) {
            setModalText(`${fileRejections[0].file.name} => ${fileRejections[0].errors[0].message}`);

        } else {
            const rejectedMessages = fileRejections.reduce((acc, fileRejection) => acc += `${fileRejection.file.name}, `, '');
            setModalText(`${rejectedMessages} => ${fileRejections[0].errors[0].message}`);
            console.log(fileRejections)
        }
    };
    const successfullyReadFileCount = readFileData.filter(fileData => fileData.loadResult === 'success').length;

    const throwDirError = () => {
        props.setError(true)
        props.setHelperText("must not be empty and should start and end with a '/'")
        props.setAlertVariant("danger")
    }

    return <>
        <MultipleFileUpload style={{opacity: props.opacity}} className="pf-v5-theme-dark" onFileDrop={handleFileDrop} dropzoneProps={{
            accept: {
                'application/zip': ['.dat', '.DAT', '.raw', '.zip'],
            },
            disabled: props.disabled,
            maxFiles: limit,
            maxSize: maxSize,
            onDropRejected: handleDropRejected
        }} isHorizontal={isHorizontal}>
            <MultipleFileUploadMain titleIcon={<UploadIcon />} titleText="Drag and drop files here" titleTextSeparator="or" infoText="Accepted file types: ZIP, RAW, DAT" />
            {showStatus && <MultipleFileUploadStatus statusToggleText={`${successfullyReadFileCount} of ${currentFiles.length} files read`} statusToggleIcon={statusIcon}>
                {
                    props.dirName != "" && ((props.dirName).slice(-1) === "/" && (props.dirName).charAt(0) === "/")
                        ?
                        <>
                            {currentFiles.map(file =>
                                <><QarUploadStatus file={file} name={file.name} size={file.size} airline={props.airline} sasToken={props.sasToken} dirName={props.dirName} onClearClick={() => removeFiles([file.name])} onReadSuccess={handleReadSuccess} onReadFail={handleReadFail} ></QarUploadStatus></>
                            )}
                            {props.setError(false)}
                            {props.setHelperText("")}
                            {props.setAlertVariant("info")}
                        </>
                        :
                        throwDirError()
                }
            </MultipleFileUploadStatus>}
            <Modal className="pf-v5-theme-dark" isOpen={!!modalText} title="Error" titleIconVariant="warning" showClose aria-label="unsupported file upload attempted" onClose={() => setModalText('')}>
                {modalText}
            </Modal>
        </MultipleFileUpload>
    </>;
};
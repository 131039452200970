
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { AggregatedDataCharts } from "../charts/AggregatedDataCharts";

export const AggregatedData = (props) => {

    const [totalFuel, setTotalFuel] = useState("NA");
    const [totalTime, setTotalTime] = useState("NA");
    const [totalCarbonEmission, setTotalCarbonEmission] = useState("NA");
    const [averageFuelKg, setAverageFuelKg] = useState("NA");
    const [averageFuelPercent, setAverageFuelPercent] = useState("NA");
    const [averageCarbonEmission, setAverageCarbonEmission] = useState("NA");

    const [totalFuelRatio, setTotalFuelRatio] = useState(0);
    const [totalTimeRatio, setTotalTimeRatio] = useState(0);
    const [totalCarbonEmissionRatio, setTotalCarbonEmissionRatio] = useState(0);
    const [averageFuelKgRatio, setAverageFuelKgRatio] = useState(0);
    const [averageFuelPercentRatio, setAverageFuelPercentRatio] = useState(0);
    const [averageCarbonEmissionRatio, setAverageCarbonEmissionRatio] = useState(0);

    const [data, setData] = useState();


    useEffect(() => {
        try {
            getSavingsReports();
        }
        catch (e) {
            console.log(e);
        }
    }, []);

    const getSavingsReports = async () => {
        const code = process.env.REACT_APP_FUNCTION_CAPTUREDSAVINGS_GETBYID_CODE;
        var requestOptions = {
            method: "GET",
            headers: {
                "x-functions-key": `${code}`
            }
        };
        try {
            let res = await fetch(`${process.env.REACT_APP_FUNCTION_CAPTUREDSAVINGS_GETBYID_URI}?AirlineID=${props.airline}&ModelID=${props.model}`, requestOptions);
            let status = await res.status;
            if (status == 200) {
                let data = await res.json();
                setData(data);
                setTotalFuel((data[0].TotalFuel).toLocaleString(
                    undefined, { maximumFractionDigits: 0 },
                ));
                setTotalTime((data[0].TotalTime).toLocaleString(
                    undefined, { maximumFractionDigits: 0 }
                ));
                setTotalCarbonEmission((data[0].TotalCarbonEmission).toLocaleString(
                    undefined, { maximumFractionDigits: 0 }
                ));
                setAverageFuelKg((data[0].AverageFuelKg).toLocaleString(
                    undefined, { maximumFractionDigits: 0 }
                ));
                setAverageFuelPercent((data[0].AverageFuelPercent).toLocaleString(
                    undefined, { minimumFractionDigits: 2 }
                ));
                setAverageCarbonEmission((data[0].AverageCarbonEmission).toLocaleString(
                    undefined, { maximumFractionDigits: 0 }
                ));

                if (Object.keys(data[1]).length > 1) {
                    console.log("data length " + Object.keys(data[1]).length)
                    setTotalFuelRatio((((data[0].TotalFuel - data[1].TotalFuel) / data[1].TotalFuel) * 100).toLocaleString(
                        undefined, { maximumFractionDigits: 0 }
                    ));
                    setTotalTimeRatio((((data[0].TotalTime - data[1].TotalTime) / data[1].TotalTime) * 100).toLocaleString(
                        undefined, { maximumFractionDigits: 0 }
                    ));
                    setTotalCarbonEmissionRatio((((data[0].TotalCarbonEmission - data[1].TotalCarbonEmission) / data[1].TotalCarbonEmission) * 100).toLocaleString(
                        undefined, { maximumFractionDigits: 0 }
                    ));
                    setAverageFuelKgRatio((((data[0].AverageFuelKg - data[1].AverageFuelKg) / data[1].AverageFuelKg) * 100).toLocaleString(
                        undefined, { maximumFractionDigits: 0 }
                    ));
                    setAverageFuelPercentRatio((((data[0].AverageFuelPercent - data[1].AverageFuelPercent) / data[1].AverageFuelPercent) * 100).toLocaleString(
                        undefined, { maximumFractionDigits: 0 }
                    ));
                    setAverageCarbonEmissionRatio((((data[0].AverageCarbonEmission - data[1].AverageCarbonEmission) / data[1].AverageCarbonEmission) * 100).toLocaleString(
                        undefined, { maximumFractionDigits: 0 }
                    ));


                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="">
                <div style={{ paddingBottom: 10 }}>
                    Aggregated Total Savings
                </div>
            </div>
            <div className="row">
                <div className="col-xl-2 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 className="mb-0">{totalFuel}</h3>
                                        <p>
                                            {
                                                totalFuelRatio > 0 || totalFuelRatio == 0
                                                    ?
                                                    <div className="text-success ml-2 mb-0 font-weight-medium">+{totalFuelRatio}%</div>
                                                    :
                                                    <div className="text-danger ml-2 mb-0 font-weight-medium">{totalFuelRatio}%</div>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-3">

                                </div>
                            </div>
                            <h6 className="text-muted font-weight-normal">Total Fuel</h6>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 className="mb-0">{totalTime}</h3>
                                        <p>
                                            {
                                                totalTimeRatio > 0 || totalTimeRatio == 0
                                                    ?
                                                    <div className="text-success ml-2 mb-0 font-weight-medium">+{totalTimeRatio}%</div>
                                                    :
                                                    <div className="text-danger ml-2 mb-0 font-weight-medium">{totalTimeRatio}%</div>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-3">

                                </div>
                            </div>
                            <h6 className="text-muted font-weight-normal">Total Time</h6>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 className="mb-0">{totalCarbonEmission}</h3>
                                        <p>
                                            {
                                                totalCarbonEmissionRatio > 0 || totalCarbonEmissionRatio == 0
                                                    ?
                                                    <div className="text-success ml-2 mb-0 font-weight-medium">+{totalCarbonEmissionRatio}%</div>
                                                    :
                                                    <div className="text-danger ml-2 mb-0 font-weight-medium">{totalCarbonEmissionRatio}%</div>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-3">

                                </div>
                            </div>
                            <h6 className="text-muted font-weight-normal">Total Carbon Emission</h6>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 className="mb-0">{averageFuelKg}</h3>
                                        <p>
                                            {
                                                averageFuelKgRatio > 0 || averageFuelKgRatio == 0
                                                    ?
                                                    <div className="text-success ml-2 mb-0 font-weight-medium">+{averageFuelKgRatio}%</div>
                                                    :
                                                    <div className="text-danger ml-2 mb-0 font-weight-medium">{averageFuelKgRatio}%</div>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-3">

                                </div>
                            </div>
                            <h6 className="text-muted font-weight-normal">Average Fuel Kg</h6>
                        </div>
                    </div>
                </div><div className="col-xl-2 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 className="mb-0">{averageFuelPercent}%</h3>
                                        <p>
                                            {
                                                averageFuelPercentRatio > 0 || averageFuelPercentRatio == 0
                                                    ?
                                                    <div className="text-success ml-2 mb-0 font-weight-medium">+{averageFuelPercentRatio}%</div>
                                                    :
                                                    <div className="text-danger ml-2 mb-0 font-weight-medium">{averageFuelPercentRatio}%</div>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-3">

                                </div>
                            </div>
                            <h6 className="text-muted font-weight-normal">Average Fuel Percent</h6>
                        </div>
                    </div>
                </div><div className="col-xl-2 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-9">
                                    <div className="d-flex align-items-center align-self-start">
                                        <h3 className="mb-0">{averageCarbonEmission}</h3>
                                        <p>
                                            {
                                                averageCarbonEmissionRatio > 0 || averageCarbonEmissionRatio == 0
                                                    ?
                                                    <div className="text-success ml-2 mb-0 font-weight-medium">+{averageCarbonEmissionRatio}%</div>
                                                    :
                                                    <div className="text-danger ml-2 mb-0 font-weight-medium">{averageCarbonEmissionRatio}%</div>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-3">

                                </div>
                            </div>
                            <h6 className="text-muted font-weight-normal">Average Carbon Emission</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    data
                        ?
                        <AggregatedDataCharts data={data}></AggregatedDataCharts>
                        :
                        <></>
                }
            </div>
        </>
    );

}
import React from 'react';
import {Box, Modal, Tab, Tabs, Typography,} from '@mui/material';
import './css/ImportJob.css';
import './css/Form.css';
import ImportAllForm from "./ImportAllForm.jsx";
import AddNewJobConfiguration from "./AddNewJobConfiguration.jsx";


function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabPanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 2}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
    };
}

const ImportJob = ({open, handleClose, handImportAllResult}) => {
    const [value, setValue] = React.useState(0);
    const boxStyle = {
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper', boxShadow: 24, p: 2, outline: 'none', width: '50%', maxWidth: '600px',
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box className="import-job-container"
                     sx={boxStyle}
                >
                    <Typography variant="h6" component="h4" gutterBottom className="import-job-heading">
                        Import Job Configuration
                    </Typography>

                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Import All" {...a11yProps(0)}/>
                        <Tab label="Create new Job" {...a11yProps(1)} />
                    </Tabs>

                    <CustomTabPanel value={value} index={0}>
                        <ImportAllForm show={value === 0}
                                       closeImportModal={handleClose}
                                       handImportAllResult={handImportAllResult}/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <AddNewJobConfiguration show={value === 1}
                                                closeImportModal={handleClose}
                                                handCreateResult={handImportAllResult}
                        />
                    </CustomTabPanel>
                </Box>
            </Modal>
        </div>
    );
};

export default ImportJob;

import {useContext, useEffect, useState} from "react";
import {LoadingContext} from "./providers/loading.provider.js";
import getAllAirline from "./api-helpers/getAllAirline.api.js";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import {ErrorMessage, Formik} from "formik";
import getTailByAirplaneId from "./api-helpers/getTailByAirlineId.api.js";
import getAllDataType from "./api-helpers/getAllDataType.api.js";
import getAllAirplaneSystem from "./api-helpers/getAllAirplaneSystem.api.js";
import getAllAirlineCodeType from "./api-helpers/getAllAirlineCodeType.api.js";
import importJobApi from "./api-helpers/importJob.api.js";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import './css/Form.css';


const AddNewJobConfiguration = ({show, closeImportModal, handCreateResult}) => {
    const tailNumberAll = 'ALL';
    const DEFAULT_TAIL_OPTION = {tailID: tailNumberAll, tailNumber: 'ALL'};
    const {startLoading, stopLoading} = useContext(LoadingContext);
    const [airlineIds, setAirlineIds] = useState([]);
    const [tailNumbers, setTailNumbers] = useState([
        DEFAULT_TAIL_OPTION
    ]);
    const [dataTypes, setDataTypes] = useState([]);
    const [airplaneSystems, setAirplaneSystems] = useState([]);
    const [airlineCodeTypes, setAirlineCodeTypes] = useState([]);
    const [isFetchData, setIsFetchData] = useState(false);
    const [initialValues, setInitialValues] = useState({
        airlineID: '',
        tailNumber: tailNumberAll,
        dataType: '',
        airplaneSystem: '',
        airlineCodeType: '',
        dateRange: 30,
    });

    const validationSchema = Yup.object({
        airlineID: Yup.string().required('Airplane ID is required'),
        tailNumber: Yup.string(),
        dataType: Yup.string().required('Data Type is required'),
        airplaneSystem: Yup.string().required('Airplane System is required'),
        dateRange: Yup.number()
            .typeError('Date Range must be a number')
            .min(1, 'Date Range must be greater than 0')
            .max(365, 'Date Range must be at most 365 days')
            .required('Date Range is required'),
        airlineCodeType: Yup.string().required('Airline Code Type is required'),
    });

    const handleFormSubmit = async (formValid, values) => {
        if (!formValid) {
            return;
        }
        const body = {
            airlineCode: values?.airlineID,
            serialNumber: values?.tailNumber === tailNumberAll ? undefined : values?.serialNumber,
            airlineCodeType: values?.airlineCodeType,
            dataType: values?.dataType,
            airplaneSystem: values?.airplaneSystem,
            dateRange: values?.dateRange,
        }
        startLoading();
        try {
            const result = await importJobApi(body);
            handCreateResult(result);
        } catch (error) {
            console.error('Error importing job:', error);
        } finally {
            stopLoading();
        }
    }

    const fetchDropdownData = async () => {
        startLoading();
        setIsFetchData(false);
        try {
            const [
                airplaneIdsResponse,
                dataTypesResponse,
                airplaneSystemsResponse,
                airlineCodeTypesResponse,
            ] = await Promise.all([
                getAllAirline(),
                getAllDataType(),
                getAllAirplaneSystem(),
                getAllAirlineCodeType(),
            ]);

            setAirlineIds([{icaoCode: '', airlineName: 'Select an airline'}, ...airplaneIdsResponse.data]);
            setDataTypes(dataTypesResponse.data);
            setAirplaneSystems(airplaneSystemsResponse.data);
            setAirlineCodeTypes(airlineCodeTypesResponse.data);

            setInitialValues({
                airlineID: '',
                tailNumber: tailNumberAll,
                dataType: dataTypesResponse.data[0].dataType,
                airplaneSystem: airplaneSystemsResponse.data[0].airplaneSystem,
                airlineCodeType: airlineCodeTypesResponse.data.find(type => type.airlineCodeType === 'icao').airlineCodeType,
                dateRange: 30,
            });
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        } finally {
            stopLoading();
            setIsFetchData(true);
        }
    };

    const handleAirlineChange = async (airlineID, props) => {
        await props.setFieldValue('tailNumber', tailNumberAll);
        await props.setFieldValue('serialNumber', '');
        if (airlineID) {
            startLoading();
            try {
                const tailsResponse = await getTailByAirplaneId(airlineID);
                setTailNumbers([DEFAULT_TAIL_OPTION, ...tailsResponse.data]);
            } catch (error) {
                console.error('Error fetching tail numbers:', error);
            } finally {
                stopLoading();
            }
        }

    }

    useEffect(() => {
        if (show) {
            fetchDropdownData().then();
        }
    }, []);


    if (!show || !isFetchData) {
        return null;
    }
    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}>
                {props => (
                    <Form className="bifrost-form-container">
                        <div className={'bifrost-form-control'}>
                            <Autocomplete
                                id="airlineAutocomplete"
                                value={
                                    airlineIds.find(e => e?.icaoCode === props.values.airlineID) || null
                                }
                                options={airlineIds}
                                getOptionLabel={(option) => option.airlineName}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Airline ID *"
                                        name="airlineID"
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {input: 'bifrost-customInput'},
                                        }}
                                        InputLabelProps={{className: 'bifrost-form-label'}}
                                    />
                                )}
                                onChange={(e, value) => {
                                    props.setFieldValue('airlineID', value?.icaoCode).then();
                                    handleAirlineChange(value?.icaoCode, props).then();
                                }}
                                classes={{option: 'bifrost-full-control'}}
                            />
                            <ErrorMessage component="div" name="airlineID" className="bifrost-error-message"/>
                        </div>
                        {/*Airline ID Field*/}

                        <div className={'bifrost-form-control'}>
                            <Autocomplete
                                id="tailNumberAutocomplete"
                                value={
                                    tailNumbers.find(e => e.tailID === props.values.tailNumber) || null
                                }
                                options={tailNumbers}
                                getOptionLabel={option => option.tailNumber}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Tail Number *"
                                        name="tailNumber"
                                        disabled={!props.values.airlineID}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {input: 'bifrost-customInput'},
                                        }}
                                        InputLabelProps={{className: 'bifrost-form-label'}}
                                    />
                                )}
                                onChange={(e, value) => {
                                    props.setFieldValue('tailNumber', value?.tailID).then();
                                    props.setFieldValue('serialNumber', value?.serialNumber).then(); // Store serialNumber
                                }}
                                disabled={!props.values.airlineID}
                                classes={{option: 'bifrost-full-control'}}
                            />
                            <ErrorMessage component="div" name="tailNumber" className="bifrost-error-message"/>
                        </div>
                        {/* tailNumber field */}

                        <div className={'bifrost-form-control'}>
                            <Autocomplete
                                id="dataTypeAutoComplete"
                                disableClearable
                                value={
                                    dataTypes.find(e => e?.dataType === props.values.dataType || null)
                                }
                                options={dataTypes}
                                getOptionLabel={(option) => option.dataType}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Data Type *"
                                        name="dataType"
                                        disabled={!props.values.airlineID}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {input: 'bifrost-customInput'},
                                        }}
                                        InputLabelProps={{className: 'bifrost-form-label'}}
                                    />
                                )}
                                onChange={(e, value) => {
                                    props.setFieldValue('dataType', value?.dataType).then();
                                }}
                                disabled={!props.values.airlineID}
                                classes={{option: 'bifrost-full-control'}}
                            />
                            <ErrorMessage component="div" name="dataType" className="bifrost-error-message"/>
                        </div>
                        {/* dataType field */}

                        <div className={'bifrost-form-control'}>
                            <Autocomplete
                                id="airplaneSystemAutoComplete"
                                disableClearable
                                value={
                                    airplaneSystems.find(e => e?.airplaneSystem === props.values.airplaneSystem || null)
                                }
                                options={airplaneSystems}
                                getOptionLabel={(option) => option.airplaneSystem}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Airplane System *"
                                        name="airplaneSystem"
                                        disabled={!props.values.airlineID}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {input: 'bifrost-customInput'},
                                        }}
                                        InputLabelProps={{className: 'bifrost-form-label'}}
                                    />
                                )}
                                onChange={(e, value) => {
                                    props.setFieldValue('airplaneSystem', value?.airplaneSystem).then();
                                }}
                                disabled={!props.values.airlineID}
                                classes={{option: 'bifrost-full-control'}}
                            />
                            <ErrorMessage component="div" name="airplaneSystem"
                                          className="bifrost-error-message"/>
                        </div>
                        {/* Airplane System field */}

                        <div className={'bifrost-form-control'}>
                            <Autocomplete
                                id="airplaneSystemAutoComplete"
                                disableClearable
                                value={
                                    airlineCodeTypes.find(e => e?.airlineCodeType === props.values.airlineCodeType || null)
                                }
                                options={airlineCodeTypes}
                                getOptionLabel={(option) => option.airlineCodeType}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Airline Code Type *"
                                        name="airlineCodeType"
                                        disabled={!props.values.airlineID}
                                        InputProps={{
                                            ...params.InputProps,
                                            classes: {input: 'bifrost-customInput'},
                                        }}
                                        InputLabelProps={{className: 'bifrost-form-label'}}
                                    />
                                )}
                                onChange={(e, value) => {
                                    props.setFieldValue('airlineCodeType', value?.airlineCodeType).then();
                                }}
                                disabled={!props.values.airlineID}
                                classes={{option: 'bifrost-full-control'}}
                            />
                            <ErrorMessage component="div" name="airlineCodeType"
                                          className="bifrost-error-message"/>
                        </div>
                        {/* Airline Code Type field */}

                        <div className={'bifrost-form-control'}>
                            <TextField id="dateRange"
                                       label="Date Range *"
                                       fullWidth={true}
                                       color={props.touched.dateRange && Boolean(props.errors.dateRange) ? 'error' : 'primary'}
                                       name="dateRange"
                                       value={props.values.dateRange}
                                       onChange={props.handleChange}
                                       onBlur={props.handleBlur}
                                       error={props.touched.dateRange && Boolean(props.errors.dateRange)}
                                       variant="outlined"
                                       disabled={!props.values.airlineID}
                                       InputProps={{
                                           classes: {input: 'bifrost-customInput'},
                                       }}
                                       InputLabelProps={{className: 'bifrost-form-label'}}
                            />
                            <ErrorMessage component="div" name="dateRange"
                                          className="bifrost-error-message"/>
                        </div>
                        {/* Date Range field */}
                        <hr/>
                        <div className="bifrost-form-button-group">
                            <button onClick={() => handleFormSubmit(props.isValid, props.values)}
                                    disabled={!props.isValid || !props.values.airlineID} className="bifrost-submit-btn"
                                    type="button">Submit
                            </button>
                            <button onClick={closeImportModal} className="bifrost-cancel-btn" type="button">Cancel
                            </button>
                        </div>
                    </Form>


                )}
            </Formik>
        </div>
    )
}
export default AddNewJobConfiguration;

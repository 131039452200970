import axios from "axios";
import { MessageCode, ResponseStatus } from "../constants/api.constants.js";

const runJobs = async (jobIDs) => {
    const apiUrl = process.env.REACT_APP_API_RUN_JOB;
    let result;

    try {
        const axiosResponse = await axios.post(apiUrl, {
            jobIDs: jobIDs
        });

        const { status, messageCode } = axiosResponse.data;
        result = status === ResponseStatus.SUCCESS && messageCode === MessageCode.SUCCESS;
    } catch (error) {
        result = false;
    }

    return result;
};

export default runJobs;
export const BIFROST_COLUMN_NAME = {
    jobIdIndex: 'ID',
    jobID: 'Job ID',
    airlineID: 'Airline ID',
    tailNumber: 'Tail Number',
    serialNumber: 'Serial Number',
    dataType: 'Data Type',
    iataCode: 'IataCode',
    type: 'Type',
    series: 'Series',
    dateRange: 'Date Range',
    lastProcessed: 'Last Processed',
    manufacturerCode: 'Manufacturer Code',
    airlineCodeType: 'Airline Code Type',
    airplaneSystem: 'Airplane System'
};

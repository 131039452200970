import React, { Component, useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Line, Bar, Pie, Scatter } from 'react-chartjs-2';
import { ListTails } from "../components/ListTails";
import Spinner from 'react-bootstrap/Spinner';
import { ListPreferences } from '../components/ListPreferences';
import { ListTriggers } from '../components/ListTriggers';
import { ListFlightProgress } from '../components/ListFlightProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { TabContext } from '@mui/lab';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AirlinePreferences } from '../components/AirlinePreferences';
import { FeatureManagement } from '../components/FeatureManagement';
import GroupIcon from '@mui/icons-material/Group';
import AirlinesIcon from '@mui/icons-material/Airlines';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { ModifyKeys } from '../components/ModifyKeys';


export const Preferences = (props) => {

  const [TSP, setTSP] = useState(<>
    <Stack spacing={1.5}>
      <Skeleton variant="text" width="60%" sx={{ bgcolor: "grey.900" }} />
    </Stack>
  </>);

  const [value, setValue] = React.useState('1');

  const handleChange = (e, newValue) => {
    console.log(newValue)
    setValue(newValue);
  };
  const [rowData, setRowData] = useState([]);
  const [featureManagement, setFeatureManagement] = useState([]);

  useEffect(() => {
    getPreferences();
    getFeatureManagement();
  }, []);

  const getPreferences = async () => {

    const headers = new Headers();

    const options = {
      method: "GET",
      headers: headers
    };

    console.log(props.airline.toUpperCase())
    const code = process.env.REACT_APP_FUNCTION_AIRLINE_PREFERENCES_GET_CODE;
    fetch(`${process.env.REACT_APP_FUNCTION_AIRLINE_PREFERENCES_GET_URI}?code=${code}&airline=airline-${props.airline}`, options)
      .then(response => response.json())
      .then(data => {
        setRowData(data);
      }
      )
      .catch(error => console.log('error', error));
  }

  const getFeatureManagement = async () => {
    const headers = new Headers();

    const options = {
      method: "GET",
      headers: headers
    };

    const code = process.env.REACT_APP_FUNCTION_FEATURE_MGMT_GET_CODE;
    fetch(`${process.env.REACT_APP_FUNCTION_FEATURE_MGMT_GET_URI}?code=${code}&airline=airline-${props.airline}`, options)
      .then(response => response.json())
      .then(data => {
        setFeatureManagement(data);
        console.log(data)
      }
      )
      .catch(error => console.log('error', error));
  }

  return (

    <div>

      <div className="page-header">
        <h3 className="page-title">Preferences</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a onClick={event => event.preventDefault()}>User Management</a></li>
            <li className="breadcrumb-item active" aria-current="page">Preferences</li>
          </ol>
        </nav>
      </div>
      <ThemeProvider theme={props.theme}>
        <CssBaseline />
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example"
              variant="fullWidth"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab icon={<GroupIcon />} iconPosition="start" label="User Preferences" value="1" />
              <Tab icon={<AirlinesIcon />} iconPosition="start" label="Airline Preferences" value="2" />
              <Tab icon={<DashboardIcon />} iconPosition="start" label="Feature Management" value="3" />
              <Tab icon={<AddToPhotosIcon />} iconPosition="start" label="Advanced Config" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">

            <div className="row">

              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body" style={{ overflowY: 'scroll', height: 300, marginBottom: 20 }} >
                    <div className="d-flex flex-row justify-content-between">
                      <h4 className="card-title mb-1">Units of Measurement Default Settings:</h4>
                      <p className="text-muted mb-1">User preferences</p>
                    </div >
                    {
                      props.groupId
                        ?
                        <ListPreferences token={props.token} airline={props.airline} theme={props.theme} />
                        :
                        <div></div>
                    }
                    <br></br>
                    <br></br>
                    <div className="d-flex flex-row justify-content-between">
                      <h4 className="card-title mb-1">Flight Progress Table Default Setting:</h4>
                      <p className="text-muted mb-1">User preferences</p>
                    </div >
                    Turn on to automatically save values for each flight.
                    {
                      props.groupId
                        ?

                        <ListFlightProgress token={props.token} airline={props.airline} />
                        :
                        <div></div>
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body" style={{ overflowY: 'scroll', height: 450, marginBottom: 20 }} >
                    <div className="d-flex flex-row justify-content-between">
                      <h4 className="card-title mb-1">Notification Trigger Default Settings:</h4>
                      <p className="text-muted mb-1">User preferences</p>
                    </div >
                    {

                      props.groupId
                        ?

                        <ListTriggers token={props.token} airline={props.airline} />
                        :
                        <div></div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value="2">
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body" style={{ overflowY: 'scroll', height: 500, marginBottom: 20 }} >
                    <div className="d-flex flex-row justify-content-between">
                      <h4 className="card-title mb-1"></h4>
                      <p className="text-muted mb-1">Airline preferences</p>
                    </div >
                    {
                      rowData
                        ?
                        <AirlinePreferences rowData={rowData} airline={props.airline} />
                        :
                        <></>
                    }
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value="3">
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body" style={{ overflowY: 'scroll', height: 500, marginBottom: 20 }} >
                    <div className="d-flex flex-row justify-content-between">
                      <h4 className="card-title mb-1"></h4>
                      <p className="text-muted mb-1">Airline preferences</p>
                    </div >
                    {
                      featureManagement
                        ?
                        <FeatureManagement featureManagement={featureManagement} airline={props.airline} />
                        :
                        <></>
                    }
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value="4">
            
                <ModifyKeys theme={props.theme} airline={props.airline} graphData={props.graphData}></ModifyKeys>
                
          </TabPanel>

        </TabContext>
      </ThemeProvider>

    </div>
  );

}


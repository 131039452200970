import React from 'react';
import { Table, Thead, Tr, Th, Tbody, Td, TreeRowWrapper, TdProps } from '@patternfly/react-table';
import LeafIcon from '@patternfly/react-icons/dist/esm/icons/leaf-icon';
import FolderIcon from '@patternfly/react-icons/dist/esm/icons/folder-icon';
import FolderOpenIcon from '@patternfly/react-icons/dist/esm/icons/folder-open-icon';
import global_BackgroundColor_150 from '@patternfly/react-tokens/dist/esm/global_BackgroundColor_150';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FileIcon from '@patternfly/react-icons/dist/esm/icons/file-icon';
import { Badge } from '@patternfly/react-core';


import { color } from 'd3-color';
import { TableEmptyState } from './TableEmptyState';

export default function BlobDataTable(props) {

    React.useEffect(() => {
        console.log(props.data)
        //renderRows(props.data)
    }, [props.data])

    const columnNames = {
        name: 'Name',
        size: 'Size',
        type: 'Type',
        tier: 'Tier',
        state: 'State',
        lastModified: 'Last Modified'
    };

    const [expandedNodeNames, setExpandedNodeNames] = React.useState(['Repositories one']);
    const [expandedDetailsNodeNames, setExpandedDetailsNodeNames] = React.useState([]);
    const [selectedNodeNames, setSelectedNodeNames] = React.useState([]);
    const getDescendants = node => {
        if (!node.files || !node.files.length) {
            return [node];
        } else {
            let files = [];
            node.files.forEach(child => {
                files = [...files, ...getDescendants(child)];
            });
            return files;
        }
    };
    const areAllDescendantsSelected = node => getDescendants(node).every(n => selectedNodeNames.includes(n.name));
    const areSomeDescendantsSelected = node => getDescendants(node).some(n => selectedNodeNames.includes(n.name));
    const isNodeChecked = node => {
        if (areAllDescendantsSelected(node)) {
            return true;
        }
        if (areSomeDescendantsSelected(node)) {
            return null;
        }
        return false;
    };
    const renderRows = ([node, ...remainingNodes], level = 1, posinset = 1, rowIndex = 0, isHidden = false) => {
        if (!node) {
            return [];
        }
        const isExpanded = expandedNodeNames.includes(node.name);
        const isDetailsExpanded = expandedDetailsNodeNames.includes(node.name);
        const isChecked = isNodeChecked(node);
        let icon = <FileIcon fontSize='small' style={{ color: "#0d6efd" }} />;
        if (node.files) {
            icon = isExpanded ? <FolderOpenIcon aria-hidden style={{ color: "#ddd206" }} /> : <FolderIcon aria-hidden style={{ color: "#ddd206" }} />;
        }
        const treeRow = {
            onCollapse: () => setExpandedNodeNames(prevExpanded => {
                const otherExpandedNodeNames = prevExpanded.filter(name => name !== node.name);
                return isExpanded ? otherExpandedNodeNames : [...otherExpandedNodeNames, node.name];
            }),
            onToggleRowDetails: () => setExpandedDetailsNodeNames(prevDetailsExpanded => {
                const otherDetailsExpandedNodeNames = prevDetailsExpanded.filter(name => name !== node.name);
                return isDetailsExpanded ? otherDetailsExpandedNodeNames : [...otherDetailsExpandedNodeNames, node.name];
            }),
            onCheckChange: (_event, isChecking) => {
                const nodeNamesToCheck = getDescendants(node).map(n => n.name);
                setSelectedNodeNames(prevSelected => {
                    const otherSelectedNodeNames = prevSelected.filter(name => !nodeNamesToCheck.includes(name));
                    return !isChecking ? otherSelectedNodeNames : [...otherSelectedNodeNames, ...nodeNamesToCheck];
                });
                props.setSelectedNodeNames(prevSelected => {
                    const otherSelectedNodeNames = prevSelected.filter(name => !nodeNamesToCheck.includes(name));
                    return !isChecking ? otherSelectedNodeNames : [...otherSelectedNodeNames, ...nodeNamesToCheck];
                })
            },
            rowIndex,
            props: {
                isExpanded,
                isDetailsExpanded,
                isHidden,
                'aria-level': level,
                'aria-posinset': posinset,
                'aria-setsize': node.files ? node.files.length : 0,
                isChecked,
                checkboxId: `checkbox_id_${node.name.toLowerCase().replace(/\s+/g, '_')}`,
                icon
            }
        };
        const childRows = node.files && node.files.length ? renderRows(node.files, level + 1, 1, rowIndex + 1, !isExpanded || isHidden) : [];
        const isOddRow = (rowIndex + 1) % 2;
        const isParent = node.files && node.files.length ? true : false
        const customStyle = {
            backgroundColor: global_BackgroundColor_150.var
        };
        return [<TreeRowWrapper key={node.name} row={{
            props: treeRow.props
        }}
            className={isOddRow ? 'odd-row-class' : 'even-row-class'}
            style={isOddRow || isParent ? {} : customStyle}
        >

            <Td treeRow={treeRow}>{node.name}</Td>
            <Td dataLabel={columnNames.size}>{node.size}</Td>
            <Td dataLabel={columnNames.type}>{node.type}</Td>
            <Td dataLabel={columnNames.tier}>{renderTier(node.tier)}</Td>
            <Td dataLabel={columnNames.state}>{renderState(node.state)}</Td>
            <Td dataLabel={columnNames.lastModified}>{node.lastModified.toString()}</Td>

        </TreeRowWrapper>, ...childRows, ...renderRows(remainingNodes, level, posinset + 1, rowIndex + 1 + childRows.length, isHidden)];
    };



    const renderTier = (tier) => {
        if (tier) {
            if (tier == "Hot") {
                return (<Badge key={1} style={{ backgroundColor: "orange" }} screenReaderText="">
                    Hot
                </Badge>)
            }
            else {
                return (<Badge key={1} screenReaderText="">
                    Cold
                </Badge>)
            }
        }
        else return
    }

    const renderState = (state) => {
        if (state) {
            if (state == "available") {
                return (<Badge key={1} style={{ backgroundColor: "#00d25b" }} screenReaderText="Unread Messages">
                    Available
                </Badge>)
            }
            else {
                return (<Badge key={1} screenReaderText="">
                    Unavailable
                </Badge>)
            }
        }
        else return
    }

    return (
        <>

            {
                props.data.length > 0
                    ?
                    <>
                        <Table isTreeTable aria-label="Tree table" className='pf-v5-theme-dark'>
                            <Thead>
                                <Tr>
                                    <Th width={40}>{columnNames.name}</Th>
                                    <Th>{columnNames.size}</Th>
                                    <Th>{columnNames.type}</Th>
                                    <Th>{columnNames.tier}</Th>
                                    <Th>{columnNames.state}</Th>
                                    <Th>{columnNames.lastModified}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>{renderRows(props.data)}</Tbody>

                        </Table>
                    </>
                    :
                    <TableEmptyState></TableEmptyState>


            }


        </>
    )

};


import React, { useState } from "react";

export const IsContractCellRenderer = (props) => {

  

  return (

    <>
      {
        props.data.IsContract == false
          ?
          <div>
            <div className=''>TRIAL
                <button style={{ backgroundColor: "transparent", border: "none" }} className="mdi mdi-alert text-warning"></button>
            </div>
          </div>
          :
          <div className=''>CONTRACT <i className='mdi mdi-check text-success'></i></div>
      }

    </>


  );

}
import IconButton from "@mui/joy/IconButton";
import {EditIcon} from "@patternfly/react-icons";

const EditButton = ({handleClick}) => {


    return (
        <>
            <IconButton color="primary" aria-label="edit" onClick={handleClick}>
                <EditIcon/>
            </IconButton>
        </>
    );
};

export default EditButton;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n
    .use(HttpBackend) // loads translations from the backend (file system via express server or similar)
    .use(initReactI18next) // passes i18n instance to react-i18next.
    .init({
        lng: 'en', // default language
        fallbackLng: 'en', // use en if detected lng is not available

        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },

        ns: ['translation'],
        defaultNS: 'translation',

        keySeparator: false,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
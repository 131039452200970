import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import './css/Modal.css';
import './css/Form.css';
import getTailByAirplaneId from "./api-helpers/getTailByAirlineId.api.js";
import getAllAirline from "./api-helpers/getAllAirline.api.js";
import getAllDataType from "./api-helpers/getAllDataType.api.js";
import getAllAirplaneSystem from "./api-helpers/getAllAirplaneSystem.api.js";
import getAllAirlineCodeType from "./api-helpers/getAllAirlineCodeType.api.js";
import {useContext, useEffect, useState} from "react";
import {LoadingContext} from "./providers/loading.provider.js";
import Form from "react-bootstrap/Form";
import getJobDetailByJobID from "./api-helpers/getJobDetailByJobID.api.js";
import editJobConfiguration from "./api-helpers/editJob.api.js";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const ConfigurationEditFrom = ({open, data, close, handleSubmitResult}) => {
    const {startLoading, stopLoading} = useContext(LoadingContext);
    const [airlineIds, setAirlineIdsIds] = useState([]);
    const [tailNumbers, setTailNumbers] = useState([]);
    const [dataTypes, setDataTypes] = useState([]);
    const [airplaneSystems, setAirplaneSystems] = useState([]);
    const [airlineCodeTypes, setAirlineCodeTypes] = useState([]);
    const [isFetchData, setIsFetchData] = useState(false);
    const [initialValues, setInitialValues] = useState({
        airlineID: '',
        tailNumber: '',
        dataType: '',
        airplaneSystem: '',
        airlineCodeType: '',
        dateRange: 1,
    });

    const fetchDropdownData = async (airlineId) => {
        try {
            // Make API requests to fetch dropdown data
            startLoading();
            setIsFetchData(false);
            const responses = await Promise.all([
                getAllAirline(),
                getTailByAirplaneId(airlineId),
                getAllDataType(),
                getAllAirplaneSystem(),
                getAllAirlineCodeType(),
                getJobDetailByJobID(data?.jobID)
            ]).then();
            const [
                airplaneIdsResponse,
                tailNumbersResponse,
                dataTypesResponse,
                airplaneSystemsResponse,
                airlineCodeTypesResponse,
                jobDetailResponse
            ] = responses;
            setAirlineIdsIds(airplaneIdsResponse?.data || []);
            setTailNumbers(tailNumbersResponse?.data || []);
            setDataTypes(dataTypesResponse?.data || []);
            setAirplaneSystems(airplaneSystemsResponse?.data || []);
            setAirlineCodeTypes(airlineCodeTypesResponse?.data || []);
            const jobDetail = jobDetailResponse?.data;
            setInitialValues({
                airlineID: data?.airlineID || '',
                tailNumber: jobDetail?.tailId || '',
                dataType: jobDetail?.dataTypeID || '',
                airplaneSystem: jobDetail?.airplaneSystemID || '',
                airlineCodeType: jobDetail?.airlineCodeTypeID || '',
                dateRange: jobDetail?.dateRange || 1,
            });
            stopLoading();
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        } finally {
            setIsFetchData(true);
        }
    };

    useEffect(() => {
        if (open) {
            fetchDropdownData(data?.airlineID).then();
        }
    }, [open]);

    // formik value and action.

    const validationSchema = Yup.object({
        airlineID: Yup.string().required('Airplane ID is required'),
        tailNumber: Yup.string().required('Tail Number is required'),
        dataType: Yup.string().required('Data Type is required'),
        airplaneSystem: Yup.string().required('Airplane System is required'),
        dateRange: Yup.number()
            .typeError('Date Range must be a number')
            .min(1, 'Date Range must be greater than 0')
            .max(365, 'Date Range must be at most 365 days')
            .required('Date Range is required'),
        airlineCodeType: Yup.string().required('Airline Code Type is required'),
    });

    const clickButtonSubmit = async (formValid, values) => {
        if (!formValid) {
            return;
        }
        const body = {
            jobID: data?.jobID,
            tailId: values?.tailNumber,
            airlineCodeTypeID: values?.airlineCodeType,
            dataTypeID: values?.dataType,
            airplaneSystemID: values?.airplaneSystem,
            dateRange: values?.dateRange,
        }
        startLoading();
        const editConfigResponseSuccess = await editJobConfiguration(body).then();
        stopLoading();
        // create optimistic UI.
        handleSubmitResult(editConfigResponseSuccess)
    }

    return (
        <>
            <Dialog open={open && isFetchData} classes={{ paper: 'Bifrost-MuiPaper-root' }}>
                <DialogTitle>Edit Job Configuration</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={() => {
                        }}>
                        {props => (
                            <Form className="bifrost-form-container">
                                <div className={'bifrost-form-control'}>
                                    <Autocomplete disabled={true}
                                                  id="airlineAutocomplete"
                                                  value={
                                                      airlineIds.find(e => e?.icaoCode === props.values.airlineID) || null
                                                  }
                                                  options={airlineIds}
                                                  getOptionLabel={(option) => option.airlineName}
                                                  renderInput={(params) => (
                                                      <TextField
                                                          {...params}
                                                          label="Airline ID *"
                                                          name="airlineID"
                                                          InputProps={{
                                                              ...params.InputProps,
                                                              classes: {input: 'bifrost-customInput'},
                                                          }}
                                                          InputLabelProps={{className: 'bifrost-form-label'}}
                                                      />
                                                  )}
                                                  onChange={(e, value) => {
                                                      props.setFieldValue('airlineID', value?.icaoCode).then();
                                                  }}
                                                  classes={{option: 'bifrost-full-control'}}
                                    />
                                    <ErrorMessage component="div" name="airlineID" className="bifrost-error-message"/>
                                </div>
                                {/*Airline ID Field*/}

                                <div className={'bifrost-form-control'}>
                                    <Autocomplete disabled={true}
                                                  id="tailNumberAutocomplete"
                                                  value={
                                                      tailNumbers.find(e => e?.tailID === props.values.tailNumber) || null
                                                  }
                                                  options={tailNumbers}
                                                  getOptionLabel={(option) => option.tailNumber}
                                                  renderInput={(params) => (
                                                      <TextField
                                                          {...params}
                                                          label="Tail Number *"
                                                          name="tailNumber"
                                                          InputProps={{
                                                              ...params.InputProps,
                                                              classes: {input: 'bifrost-customInput'},
                                                          }}
                                                          InputLabelProps={{className: 'bifrost-form-label'}}
                                                      />
                                                  )}
                                                  onChange={(e, value) => {
                                                      props.setFieldValue('tailNumber', value?.tailID).then();
                                                  }}
                                                  classes={{option: 'bifrost-full-control'}}
                                    />
                                    <ErrorMessage component="div" name="tailNumber" className="bifrost-error-message"/>
                                </div>
                                {/* tailNumber field */}

                                <div className={'bifrost-form-control'}>
                                    <Autocomplete
                                        id="dataTypeAutoComplete"
                                        disableClearable
                                        value={
                                            dataTypes.find(e => e?.dataTypeID === props.values.dataType || null)
                                        }
                                        options={dataTypes}
                                        getOptionLabel={(option) => option.dataType}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Data Type *"
                                                name="dataType"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    classes: {input: 'bifrost-customInput'},
                                                }}
                                                InputLabelProps={{className: 'bifrost-form-label'}}
                                            />
                                        )}
                                        onChange={(e, value) => {
                                            props.setFieldValue('dataType', value?.dataTypeID).then();
                                        }}
                                        classes={{option: 'bifrost-full-control'}}
                                    />
                                    <ErrorMessage component="div" name="dataType" className="bifrost-error-message"/>
                                </div>
                                {/* dataType field */}

                                <div className={'bifrost-form-control'}>
                                    <Autocomplete
                                        id="airplaneSystemAutoComplete"
                                        disableClearable
                                        value={
                                            airplaneSystems.find(e => e?.airplaneSystemID === props.values.airplaneSystem || null)
                                        }
                                        options={airplaneSystems}
                                        getOptionLabel={(option) => option.airplaneSystem}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Airplane System *"
                                                name="airplaneSystem"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    classes: {input: 'bifrost-customInput'},
                                                }}
                                                InputLabelProps={{className: 'bifrost-form-label'}}
                                            />
                                        )}
                                        onChange={(e, value) => {
                                            props.setFieldValue('airplaneSystem', value?.airplaneSystemID).then();
                                        }}
                                        classes={{option: 'bifrost-full-control'}}
                                    />
                                    <ErrorMessage component="div" name="airplaneSystem"
                                                  className="bifrost-error-message"/>
                                </div>
                                {/* Airplane System field */}

                                <div className={'bifrost-form-control'}>
                                    <Autocomplete
                                        id="airplaneSystemAutoComplete"
                                        disableClearable
                                        value={
                                            airlineCodeTypes.find(e => e?.airlineCodeTypeID === props.values.airlineCodeType || null)
                                        }
                                        options={airlineCodeTypes}
                                        getOptionLabel={(option) => option.airlineCodeType}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Airline Code Type *"
                                                name="airlineCodeType"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    classes: {input: 'bifrost-customInput'},
                                                }}
                                                InputLabelProps={{className: 'bifrost-form-label'}}
                                            />
                                        )}
                                        onChange={(e, value) => {
                                            props.setFieldValue('airlineCodeType', value?.airlineCodeTypeID).then();
                                        }}
                                        classes={{option: 'bifrost-full-control'}}
                                    />
                                    <ErrorMessage component="div" name="airlineCodeType"
                                                  className="bifrost-error-message"/>
                                </div>
                                {/* Airline Code Type field */}

                                <div className={'bifrost-form-control'}>
                                    <TextField id="dateRange"
                                               label="Date Range *"
                                               fullWidth={true}
                                               color={props.touched.dateRange && Boolean(props.errors.dateRange) ? 'error' : 'primary'}
                                               name="dateRange"
                                               value={props.values.dateRange}
                                               onChange={props.handleChange}
                                               onBlur={props.handleBlur}
                                               error={props.touched.dateRange && Boolean(props.errors.dateRange)}
                                               variant="outlined"
                                               InputProps={{
                                                   classes: {input: 'bifrost-customInput'},
                                               }}
                                               InputLabelProps={{className: 'bifrost-form-label'}}
                                    />
                                    <ErrorMessage component="div" name="dateRange"
                                                  className="bifrost-error-message"/>
                                </div>{/* Date Range field */}

                                <hr/>
                                <div className="bifrost-form-button-group">
                                    <button onClick={() => clickButtonSubmit(props.isValid, props.values)}
                                            disabled={!props.isValid} className="bifrost-submit-btn"
                                            type="button">Submit
                                    </button>
                                    <button onClick={close} className="bifrost-cancel-btn" type="button">Cancel
                                    </button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ConfigurationEditFrom;

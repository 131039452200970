import {useState} from 'react';

/**
 *
 * use to show info modal only.
 */
const useNormalModal = () => {
    const [open, setOpen] = useState(false);

    const toggleModal = (state) => {
        setOpen(state);
    }

    return [
        open,
        toggleModal
    ];
}

export default useNormalModal;

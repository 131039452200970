import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { QarDropZone } from '../components/QarDropZone';
import { Button } from 'react-bootstrap';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ListBlobs } from '../components/ListBlobs';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Autocomplete from '@mui/material/Autocomplete';
import PlusCircleIcon from '@patternfly/react-icons/dist/esm/icons/plus-circle-icon';
import { Popover, Button as MUIButton, TextInput, Alert, Badge, Modal, List, ListItem, ModalVariant } from '@patternfly/react-core';
import FolderIcon from '@mui/icons-material/Folder';
import Box from '@mui/material/Box';

export const FlightData = (props) => {

    const [value, setValue] = React.useState("folder name...");
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState("required");
    const [alertVariant, setAlertVariant] = React.useState("info");
    const [alertHidden, setAlertHidden] = React.useState(false);

    const [dirName, setDirName] = useState("")

    const [folders, setFolders] = useState([]);
    const [configFiles, setConfigFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalToggle = _event => {
        setIsModalOpen(!isModalOpen);
    };
    const [selectedFolder, setSelectedFolder] = useState("777"),
        onInputSelectedFolder = ({ target: { value } }) => {
            setSelectedFolder(value);
        }
    const [sasToken, setSasToken] = useState();
    const [disabled, setDisabled] = useState(true);
    const [opacity, setOpacity] = useState(.3);


    useEffect(() => {
        leaseSas()
    }, [sasToken]);

    const leaseSas = async () => {
        const headers = new Headers();

        const options = {
            method: "GET",
            headers: headers
        };

        const code = process.env.REACT_APP_FUNCTION_LEASE_SAS_CODE;
        const res = await fetch(`${process.env.REACT_APP_FUNCTION_LEASE_SAS_URI}?code=${code}`, options);
        const status = res.status
        const signature = await res.text()
        if (status === 200) {
            //console.log(signature)
            setSasToken(signature)
        }
        else {
        }
    }

    const [isModalAlertOpen, setIsModalAlertOpen] = React.useState(false);
    const alertHide = _event => {
        setIsModalAlertOpen(false);
        setIsModalOpen(false)
    };
    const alertShow = _event => {
        setIsModalAlertOpen(true);
    };

    const alertCancel = _event => {
        setIsModalAlertOpen(false);
    };

    return (
        <>
            <div className="page-header">
                <div>
                    <h3 className="page-title">Flight Data</h3>
                    <Badge key={1} screenReaderText="Unread Messages">
                        airline-{props.airline}
                    </Badge>
                </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a onClick={event => event.preventDefault()}>Airline Management</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Flight Data</li>
                    </ol>
                </nav>
            </div>
            <div className="col-xl-12 col-sm-6 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex flex-row justify-content-between">
                            <div>
                                {/*
                                    <Button size="lg" variant="primary" onClick={handleModalToggle}>
                                    <FileUploadIcon></FileUploadIcon>
                                    Upload File
                                </Button>
    */}
                            </div>

                        </div >

                        <Modal className="pf-v5-theme-dark" title="Upload Flight Data" isOpen={isModalOpen} onClose={alertShow} description="" actions={[<Button key="confirm" variant="primary" onClick={alertShow}>
                            Close
                        </Button>]}>
                            {
                                sasToken && folders
                                    ?
                                    <>
                                        <Alert hidden={alertHidden} variant={alertVariant} title="Instructions" ouiaId="InfoAlert">
                                            <List>
                                                <ListItem>Do not upload more than 250 files at a time. Do not close your browser or move away from this view while uploading process is in place.</ListItem>
                                                <ListItem>Select folder from dropdown list or specify a new one (must start and end with a slash "/")</ListItem>
                                            </List>
                                        </Alert>

                                        {console.log(folders)}
                                        <ThemeProvider theme={props.theme}>
                                            <CssBaseline />

                                            {


                                                <Autocomplete
                                                    inputValue={dirName}
                                                    onInputChange={(event, newInputValue) => {
                                                        setDirName(newInputValue);
                                                        setDisabled(false);
                                                        setOpacity(1);
                                                    }}
                                                    id="controllable-states-demo"
                                                    options={folders}
                                                    getOptionLabel={(option) => (option.name).replace(`${props.airline.toUpperCase()}`, "")}
                                                    freeSolo
                                                    sx={{ width: 300, marginTop: 1 }}
                                                    renderInput={(params) => <TextField {...params} error={error} variant='standard' color="warning"
                                                        focused label="Folder name" helperText={helperText} />}
                                                    renderOption={(properties, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...properties}>
                                                            <FolderIcon fontSize='small'></FolderIcon>
                                                            {
                                                                (option.name).replace(`${(props.airline).toUpperCase()}`, "")
                                                            }
                                                        </Box>
                                                    )}
                                                />


                                            }



                                        </ThemeProvider>

                                        <br></br>
                                        <QarDropZone setConfigFiles={setConfigFiles} airline={props.airline} disabled={disabled} opacity={opacity} sasToken={sasToken} dirName={dirName} setError={setError} setHelperText={setHelperText} setAlertVariant={setAlertVariant} />
                                    </>
                                    :
                                    <></>
                            }

                            <Modal  width="100%" height="100%" className="pf-v5-theme-dark" variant={ModalVariant.large} title="Upload Flight Data" isOpen={isModalAlertOpen} onClose={alertHide} actions={[
                                <Button key="confirm" variant="warning" onClick={alertHide}>
                                    Confirm
                                </Button>, <Button key="cancel" variant="link" onClick={alertCancel}>
                                    Cancel
                                </Button>]}>
                                <Alert className="pf-v5-theme-dark" variant="warning" title="Warning" ouiaId="InfoAlert">
                                    <p>If you close this window ongoing uploads will continue to run in the background but you won't be able to recover this view. Are you sure you want to procede and close?</p>
                                </Alert>
                            </Modal>

                        </Modal>
                        <br></br>
                        <Alert className="pf-v5-theme-dark" variant="info" title="Beta feature" ouiaId="InfoAlert">
                            <p>This beta component is currently under review and is still open for further improvement. Unprocessed flight data is stored for up to 3 months, it will be archived after that period and won't be displayed.</p>
                        </Alert>
                        <ListBlobs airline={props.airline} theme={props.theme} setFolders={setFolders} handleModal={handleModalToggle}></ListBlobs>
                    </div>
                </div>
            </div>

        </>
    );
}
import "@patternfly/react-core/dist/styles/base.css";

import React, { useEffect } from 'react';
import { FileRejection } from 'react-dropzone';
import { MultipleFileUpload, MultipleFileUploadMain, MultipleFileUploadStatus, MultipleFileUploadStatusItem, Modal, Checkbox, DropEvent } from '@patternfly/react-core';
import UploadIcon from '@patternfly/react-icons/dist/esm/icons/upload-icon';
import { QarUploadStatus } from "./QarUploadStatus";

export const InstructionsDropZone = (props) => {
    const [isHorizontal, setIsHorizontal] = React.useState(false);
    const [currentFiles, setCurrentFiles] = React.useState([]);
    const [readFileData, setReadFileData] = React.useState([]);
    const [showStatus, setShowStatus] = React.useState(false);
    const [statusIcon, setStatusIcon] = React.useState('inProgress');
    const [modalText, setModalText] = React.useState('');
    const limit = 1

    if (!showStatus && currentFiles.length > 0) {
        setShowStatus(true);
    }

    useEffect(() => {
        if (readFileData.length < currentFiles.length) {
            setStatusIcon('inProgress');
        } else if (readFileData.every(file => file.loadResult === 'success')) {
            setStatusIcon('success');
        } else {
            setStatusIcon('danger');
        }
    }, [readFileData, currentFiles]);

    const removeFiles = namesOfFilesToRemove => {
        const newCurrentFiles = currentFiles.filter(currentFile => !namesOfFilesToRemove.some(fileName => fileName === currentFile.name));
        setCurrentFiles(newCurrentFiles);
        const newReadFiles = readFileData.filter(readFile => !namesOfFilesToRemove.some(fileName => fileName === readFile.fileName));
        setReadFileData(newReadFiles);
    };
    const handleFileDrop = (_event, droppedFiles) => {
        if (droppedFiles.length <= limit) {
            const currentFileNames = currentFiles.map(file => file.name);
            const reUploads = droppedFiles.filter(droppedFile => currentFileNames.includes(droppedFile.name));
            Promise.resolve().then(() => removeFiles(reUploads.map(file => file.name))).then(() => setCurrentFiles(prevFiles => [...prevFiles, ...droppedFiles]));
        }
        else {
            alert(`"Do not upload more than ${limit} files simultaneously`)
        }
    };
    const handleReadSuccess = (data, file) => {
        setReadFileData(prevReadFiles => [...prevReadFiles, {
            data,
            fileName: file.name,
            loadResult: 'success'
        }]);
        console.log(currentFiles)
        props.setInstructionFiles(currentFiles);
        };
    const handleReadFail = (error, file) => {
        setReadFileData(prevReadFiles => [...prevReadFiles, {
            loadError: error,
            fileName: file.name,
            loadResult: 'danger'
        }]);
    };
    const handleDropRejected = fileRejections => {
        if (fileRejections.length === 1) {
            setModalText(`${fileRejections[0].file.name} is not an accepted file type`);
        } else {
            const rejectedMessages = fileRejections.reduce((acc, fileRejection) => acc += `${fileRejection.file.name}, `, '');
            setModalText(`${rejectedMessages}are not accepted file types`);
        }
    };
    const successfullyReadFileCount = readFileData.filter(fileData => fileData.loadResult === 'success').length;

    

    return <>
        <MultipleFileUpload className="pf-v5-theme-dark" onFileDrop={handleFileDrop} dropzoneProps={{
            accept: {
                'application/zip': ['.pdf'],
            },
            onDropRejected: handleDropRejected
        }} isHorizontal={isHorizontal}>
            <MultipleFileUploadMain titleIcon={<UploadIcon />} titleText="Drag and drop files here" titleTextSeparator="or" infoText="Accepted file types: PDF" />
            {showStatus && <MultipleFileUploadStatus statusToggleText={`${successfullyReadFileCount} of ${currentFiles.length} files read`} statusToggleIcon={statusIcon}>
                
                    
            {currentFiles.map(file => <MultipleFileUploadStatusItem file={file} key={file.name} onClearClick={() => removeFiles([file.name])} onReadSuccess={handleReadSuccess} onReadFail={handleReadFail} />)}

                            
                        
            </MultipleFileUploadStatus>}
            <Modal className="pf-v5-theme-dark" isOpen={!!modalText} title="Unsupported file" titleIconVariant="warning" showClose aria-label="unsupported file upload attempted" onClose={() => setModalText('')}>
                {modalText}
            </Modal>
        </MultipleFileUpload>
    </>;
};
import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './app/App.jsx';
import "./i18n.js";
import * as serviceWorker from './serviceWorker.js';
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./authConfig.js";
import {SnackbarProvider} from 'notistack';

const msalInstance = new PublicClientApplication(msalConfig);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
        <MsalProvider instance={msalInstance}>
            <SnackbarProvider>
                <App/>
            </SnackbarProvider>
        </MsalProvider>
    </BrowserRouter>
);

serviceWorker.unregister();
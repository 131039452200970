import {MessageCode, ResponseStatus} from "../constants/api.constants.js";

export const TIMEOUT = 3000;
export const RETRY_TIMES = 5;

export function handleSuccessResponse(axiosResponse) {
    let result = [];
    let errorRes = null;
    const {status, messageCode, data, messageString} = axiosResponse.data
    if (status === ResponseStatus.SUCCESS && messageCode === MessageCode.SUCCESS) {
        result = data;
    } else {
        // This is business exception.
        errorRes = {messageCode, message: messageString};
    }
    return {data: result, error: errorRes, success: true};
}

export function handleErrorResponse(error) {
    return {data: null, error, success: false};
}

export const handleSingleResponse = async (axiosPromise) => {
    try {
        const succeededResponse = await axiosPromise;
        return handleSuccessResponse(succeededResponse);
    } catch (error) {
        return handleErrorResponse(error);
    }
}

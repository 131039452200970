import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

const columns = [
    { field: 'id', headerName: 'Event ID', flex: 1 },
    { field: 'TSPCalibrationReminderID', headerName: 'TSP Calibration Reminder ID', flex: 1 },
    { field: 'Previous', headerName: 'Previous Deadline', flex: 1 },
    { field: 'Next', headerName: 'Next Deadline', flex: 1 },
    { field: 'Date', headerName: 'Date Sent', flex: 1 },
    { field: 'Recipient', headerName: 'Recipient', flex: 1 },
];

export default function TSPCalibrationDataTable(props) {
    return (
        <div style={{ height: "100%", flex:1 }}>
            <DataGrid
                rows={props.tspCalibrationEventData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{ border: 0 }}
            />
        </div>
    );
}
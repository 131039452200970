import axios from "axios";
import {MessageCode, ResponseStatus} from "../constants/api.constants.js";

const TIMEOUT = 3000;
const RETRY_TIMES = 2;

const handleSuccessResponse = (axiosResponse) => {
    let result = [];
    let errorRes = null;
    const {status, messageCode, data, messageString} = axiosResponse.data
    if (status === ResponseStatus.SUCCESS && messageCode === MessageCode.SUCCESS) {
        result = data?.map((record, index) => ({
            ...record,
            jobIdIndex: index + 1,
            selected: false
        }));
    } else {
        // This is business exception.
        errorRes = {messageCode, message: `getAllConfiguration(): ${messageString}`};
    }
    return {data: result, error: errorRes, success: true};
}

const handleErrorResponse = (error) => {
    return {data: null, error, success: false};
}

const handleSingleResponse = async (axiosPromise) => {
    try {
        const succeededResponse = await axiosPromise;
        return handleSuccessResponse(succeededResponse);
    } catch (error) {
        return handleErrorResponse(error);
    }
}

const getAllConfiguration = async (option) => {
    const timeout = option?.timeout || TIMEOUT;
    const retryTimes = option?.retryTimes || RETRY_TIMES;
    let count = 1;
    let succeededResponse = null;
    const configApiUrl = process.env.REACT_APP_API_GET_ALL_CONFIGURATION;
    try {
        // try call api at the first time.
        succeededResponse = await axios.get(configApiUrl, {timeout});
        console.log('call api  success at first time / no timeout.');
        return handleSuccessResponse(succeededResponse);
    } catch (e) {
        console.log('call api get configuration error at first time: try again');
        // as axios will throw an error in case of timeout, need this try-catch block
        // if the first time api call error, try again.
        let result = null;
        while (retryTimes > count) {
             result = await handleSingleResponse(axios.get(configApiUrl));
            if (result?.success === true) {
                console.log(`Try API ${count}: successfully.`);
                break;
            } else {
                console.log(`Try API ${count}: failed, try again.`);
                count++
            }
        }
        return result;
    }
}
export default getAllConfiguration;

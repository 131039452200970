import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export const SavingsReports = (props) => {

    const [sasToken, setSasToken] = useState();


    const leaseSas = async () => {
        const headers = new Headers();

        const options = {
            method: "GET",
            headers: headers
        };

        const code = process.env.REACT_APP_FUNCTION_GROUND_LEASE_SAS_CODE;
        const res = await fetch(`${process.env.REACT_APP_FUNCTION_GROUND_LEASE_SAS_URI}?code=${code}`, options);
        const status = res.status
        const signature = await res.text()
        if (status === 200) {
            setSasToken(signature)
        }
        else {
            console.log(signature)
        }
    }

    const { BlobServiceClient } = require('@azure/storage-blob');

    const blobSasUrl = `https://${process.env.REACT_APP_GROUND_STORAGE_ACCOUNT}.blob.core.windows.net`;

    // Create a new BlobServiceClient
    const blobServiceClient = new BlobServiceClient(
        `${blobSasUrl}?${sasToken}`,
        null
    );

    const [savingsReports, setSavingsReports] = useState([]);

    useEffect(() => {
        leaseSas();
        listSavingsReports();
    }, []);

    const listSavingsReports = async () => {
        const list = [];

        // create container
        const containerName = `captured-savings-reports`;
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // In loops, blob is BlobItem
        // Use BlobItem.name to get BlobClient or BlockBlobClient
        // The get `url` property
        for await (const blob of containerClient.listBlobsFlat({ prefix: `${props.airline}/${props.model}/reports/` })) {

            // Get Blob Client from name, to get the URL
            const tempBlockBlobClient = containerClient.getBlockBlobClient(blob.name);

            // Display blob name and URL
            var data = { key: `${blob.name}`, value: `${tempBlockBlobClient.url}` };
            list.push(data);
        }

        setSavingsReports(list);

    }

    const renderReports = (data) => {
        return (
            <>
                <div style={{ paddingBottom: 10 }}>
                    Savings Reports
                </div>
                <Accordion >
                    <Card>
                        <Card.Header style={{}}>
                            <Accordion.Toggle as={Button}
                                variant="link" eventKey="0" >
                                <i className="mdi mdi-chevron-double-down icon-md" style={{ fontSize: 18 }}>{props.fleet}</i>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body style={{}}>
                                {<ul>
                                    {
                                        (data.value != "")
                                            ?
                                            <i className="mdi mdi-arrow-down-bold-circle-outline" key={data.key}><a href={data.value}> {data.key}</a></i>
                                            :
                                            <>No FDR files to show.</>
                                    }

                                </ul>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
        );
    };

    return (
        <>
            {
                savingsReports.length > 0
                    ?
                    savingsReports.map(renderReports)
                    :
                    <></>
            }
        </>
    );
}
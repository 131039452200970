import React, { useState, useEffect } from "react";
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Check from '@mui/icons-material/Check';
import Typography from '@mui/joy/Typography';
import { extendTheme } from '@mui/joy/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MuiIconButton from '@mui/material/IconButton';
import { ListItemButton } from "@mui/material";
import moment from 'moment'

const materialTheme = materialExtendTheme();
const theme = extendTheme({ cssVarPrefix: 'demo' });

export default function CommentBox(props) {
    const [italic, setItalic] = React.useState(false);
    const [fontWeight, setFontWeight] = React.useState('normal');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [text, setText] = React.useState(''),
        onInputSetText = ({ target: { value } }) => {
            setText(value);
        };
    const addEmoji = (emoji) => () => setText(`${text}${emoji}`);
    const [comments, setComments] = useState(null);


    useEffect(() => {
        getComments();
    }, [text]);

    const getComments = async () => {
        const code = process.env.REACT_APP_FUNCTION_COMMENT_GET_CODE;
        let options = {
            method: "GET",
            headers: {
                "x-functions-key": `${code}`
            }
        };

        let res = await fetch(process.env.REACT_APP_FUNCTION_COMMENT_GET_URI, options);
        let data = await res.json();
        let status = res.status;

        if (status == 200) {
            setComments(data);
        }
        else {
            console.log("error")

        }
    }


    const commentSubmit = async () => {

        let data = JSON.stringify({
            "FleetID": `${props.fleet}`,
            "Content": `${text}`,
            "Author": `${props.user}`
        });

        const code = process.env.REACT_APP_FUNCTION_COMMENT_INSERT_CODE;
        let options = {
            method: "POST",
            headers: {
                "x-functions-key": `${code}`
            },
            body: data
        };

        let res = await fetch(process.env.REACT_APP_FUNCTION_COMMENT_INSERT_URI, options);
        let status = res.status;

        if (status == 200) {
            getComments();
        }
        else {


        }
    }

    const deleteComment = async (id) => {
        const code = process.env.REACT_APP_FUNCTION_COMMENT_DELETE_CODE;
        let options = {
            method: "DELETE",
            headers: {
                "x-functions-key": `${code}`
            }
        };

        let res = await fetch(`${process.env.REACT_APP_FUNCTION_COMMENT_DELETE_URI}?id=${id}`, options);
        let status = res.status;

        if (status == 201) {
            getComments();
        }
        else {


        }
    }

    return (
        <>
            <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
                <JoyCssVarsProvider
                    defaultMode="dark"
                    colorSchemeSelector="#demo_dark-mode-by-default"
                    //
                    // the local storage key to use
                    modeStorageKey="demo_dark-mode-by-default"
                    //
                    // set as root provider
                    disableNestedContext
                >
                    <div id="demo_dark-mode-by-default">

                        <FormControl>
                            <FormLabel>Your comment</FormLabel>
                            <Textarea
                                placeholder="Type something here…"
                                value={text}
                                onChange={onInputSetText}
                                minRows={3}
                                endDecorator={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 'var(--Textarea-paddingBlock)',
                                            pt: 'var(--Textarea-paddingBlock)',
                                            borderTop: '1px solid',
                                            borderColor: 'divider',
                                            flex: 'auto',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                                            <IconButton variant="outlined" color="neutral" onClick={addEmoji('👍')}>
                                                👍
                                            </IconButton>
                                            <IconButton variant="outlined" color="neutral" onClick={addEmoji('✔')}>
                                                ✔
                                            </IconButton>
                                            <IconButton variant="outlined" color="neutral" onClick={addEmoji('😍')}>
                                                😍
                                            </IconButton>
                                        </Box>


                                        <Typography level="body3" sx={{ ml: 'auto' }}>
                                            {text.length} character(s)
                                        </Typography>
                                        <Button sx={{ ml: 'auto' }} onClick={commentSubmit}>Send</Button>

                                    </Box>

                                }
                                sx={{
                                    minWidth: 300,
                                    fontWeight,
                                    fontStyle: italic ? 'italic' : 'initial',
                                }}
                            />
                        </FormControl>
                    </div>
                </JoyCssVarsProvider>


            </MaterialCssVarsProvider>
            {comments
                ?
                comments.map(node => {
                    return (
                        <List key={node.CommentID} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <ListItemButton alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={node.Author}
                                    secondary={
                                        <React.Fragment>
                                            {moment(node.DateAsString).format("MMMM Do YYYY")}
                                            - {node.Content}
                                        </React.Fragment>
                                    }
                                />
                                <MuiIconButton
                                    edge="start"
                                    color="inherit"
                                    //onClick={handleClose}
                                    aria-label="close"
                                >
                                    <RemoveCircleIcon sx={{ color: 'red' }} onClick={deleteComment(node.CommentID)} />
                                </MuiIconButton>
                            </ListItemButton>
                        </List>
                    )
                })
                :
                <></>
            }



        </>
    );
}

import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './css/Bifrost.css';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from "./ConfirmModal.jsx";
import InfoModal from "./InfoModal.jsx";
import LoadingScreen from "./LoadingScreen.jsx";
import {BIFROST_COLUMN_NAME} from "./constants/bifrost.constant.js";
import getAllConfiguration from "./api-helpers/getAllConfiguration.api.js";
import useNormalModal from "./hooks/useNomralModal.hook.js";
import {LoadingContext} from "./providers/loading.provider.js";
import EditButton from "./EditButton.jsx";
import cleanJobConfiguration from "./api-helpers/deleteConfig.api.js";
import ConfigurationEditFrom from "./ConfigurationEditFrom.jsx";
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import {ImportIcon, RunningIcon} from "@patternfly/react-icons";
import ImportJob from "./ImportJob.jsx";
import runJobsApi from "./api-helpers/runJobs.api.js";


export const Bifrost = () => {
    const {loading, startLoading, stopLoading} = useContext(LoadingContext);
    const [configurationData, setConfigurationData] = useState([]);
    const [buttonClearFilter, setButtonClearFilter] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [gridApi, setGridApi] = useState(null);
    // edit dialog state.
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editDialogData, setEditDialogData] = useState(null);

    const gridRef = useRef(null);
    const [open, toggleModal] = useNormalModal();
    const [importJobModalOpen, setImportJobModalOpen] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        startLoading();
        const fetchAllConfiguration = async () => {
            const {data, error} = await getAllConfiguration()
            if (error) {
                // Open modal to show error message.
                toggleModal(true);
                setResponseMessage(error?.message)
            } else {
                setConfigurationData(data)
                setShowDeleteButton(false);
            }
        };
        fetchAllConfiguration().then(() => stopLoading());
        if (gridApi) {
            gridApi.deselectAll();
        }
    }

    const clickEditButtonAction = (params) => {
        setOpenEditDialog(true);
        setEditDialogData(params.data);
    }

    const autoSizeStrategy = useMemo(() => ({type: 'fitGridWidth'}), []);
    const columnDefs = [
        {
            field: 'selected',
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            resizable: false, // Prevent resizing
            width: 36, // Fixed width
            suppressSizeToFit: true, // Prevent auto sizing to fit the grid,
        },
        {
            headerName: '',
            width: 70,
            cellRenderer: (params) => <EditButton handleClick={() => clickEditButtonAction(params)}/>,
        },
        {
            field: 'jobIdIndex',
            headerName: 'ID',
            resizable: true,
            width: 70,
            suppressSizeToFit: true,
            sortable: true,
            filter: true
        },
        ...Object.entries(BIFROST_COLUMN_NAME).filter(([field]) => !['jobIdIndex', 'jobID', 'lastProcessed', 'manufacturerCode', 'airlineCodeType', 'airplaneSystem'].includes(field)).map(([field, name]) => ({
            field,
            headerName: name,
            sortable: true,
            filter: true,
            resizable: true,
            width: 130
        })),
        {
            field: 'lastProcessed',
            headerName: BIFROST_COLUMN_NAME.lastProcessed,
            resizable: true,
            suppressSizeToFit: true,
            sortable: true,
            filter: 'agDateColumnFilter',
            width: 170,
            valueFormatter: params => {
                if (params.data && params.data.lastProcessed) {
                    const date = new Date(params.data.lastProcessed);
                    // mm/dd/yyyy
                    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
                }
                return '';
            },
            valueGetter: params => {
                if (params.data && params.data.lastProcessed) {
                    const date = new Date(params.data.lastProcessed);
                    // mm/dd/yyyy
                    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
                }
                return null;
            },
            filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    if (cellValue == null) return -1;
                    let [month, day, year] = cellValue.split("/").map(v => parseInt(v, 10));
                    let cellDate = new Date(year, month - 1, day);
                    cellDate.setHours(0, 0, 0, 0);
                    if (cellDate.getTime() === filterLocalDateAtMidnight.getTime()) {
                        return 0;
                    } else if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                },
            },
        },
        {
            field: 'manufacturerCode',
            headerName: BIFROST_COLUMN_NAME.manufacturerCode,
            resizable: true,
            sortable: true,
            filter: true,
            width: 130
        },
        {
            field: 'airlineCodeType',
            headerName: BIFROST_COLUMN_NAME.airlineCodeType,
            resizable: true,
            sortable: true,
            filter: true,
            width: 130
        },
        {
            field: 'airplaneSystem',
            headerName: BIFROST_COLUMN_NAME.airplaneSystem,
            resizable: true,
            sortable: true,
            filter: true,
            width: 130
        }
    ];

    const onConfirmDelete = async () => {
        startLoading();
        const selectedRows = getCheckedFilterData();
        const jobIDList = selectedRows.map(e => e.jobID);
        const result = await cleanJobConfiguration(jobIDList)
        if (result) {
            setConfigurationData(prevData => prevData.filter(record => !jobIDList.includes(record.jobID)));
            setResponseMessage("Delete successfully. The job is deleting nightly.");
            setShowDeleteButton(false);
        } else {
            setResponseMessage("Delete failed, please contact administrator for more information");
        }
        stopLoading();
        toggleModal(true);
        if (gridApi) {
            gridApi.deselectAll();
        }
    }

    const detectFilterCheckedRow = () => {
        // show display checked row.
        const checkedFilterRow = getCheckedFilterData()
        setShowDeleteButton(checkedFilterRow && checkedFilterRow.length > 0);
    }

    const getCheckedFilterData = () => {
        const selectedNodes = gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);

        // Non tick
        if (selectedData.length === 0) {
            return [];
        }

        const isFilterApplied = JSON.stringify(gridApi.getFilterModel()) !== "{}";
        const totalRowsCount = gridApi.getModel().getRowCount();
        const allSelected = selectedNodes.length === totalRowsCount;

        // tick all (filter or non filter)
        if (allSelected) {
            if (isFilterApplied) {
                // Collect all filtered data
                let filteredData = [];
                gridApi.forEachNodeAfterFilter(node => filteredData.push(node.data));
                return filteredData;
            } else {
                // Collect all data
                let allData = [];
                gridApi.forEachNode(node => allData.push(node.data));
                return allData;
            }
        }

        // other cases
        return selectedData;
    };

    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
        setGridApi(params.api);
    }

    const onFirstDataRendered = (params) => {
        params.columnApi.autoSizeColumns(null, true)
    }

    const onFilterChange = () => {
        const filterModel = gridApi.getFilterModel();
        setButtonClearFilter(Object.keys(filterModel).length > 0);
    };

    const handleCloseEditModal = () => {
        setOpenEditDialog(false);
    }

    const handleEditConfigResponse = (result) => {
        handleCloseEditModal();
        const message = result ? 'Edit Configuration Successfully' : 'Edit Configuration Failed';
        setResponseMessage(message);
        toggleModal(true)
        if (result) {
            // optimistic UI
            setConfigurationData(configurationData.map(row => {
                if (row?.jobID === result?.jobID) {
                    return {...result, jobIdIndex: row.jobIdIndex}
                } else {
                    return row;
                }
            }));
        }
    }

    const openImportModal = () => {
        setImportJobModalOpen(true);
    }

    const closeImportModal = () => {
        setImportJobModalOpen(false);
    }

    const handImportAllResult = (result) => {
        toggleModal(true);
        setImportJobModalOpen(false);
        if (result) {
            setResponseMessage('[Success]: importing Job(s).');
        } else {
            setResponseMessage('[ERROR]: Please contact administrator for more information.')
        }
    }

    const runJobs = async () => {
        startLoading();
        const selectedRows = getCheckedFilterData();
        const jobIDs = selectedRows.map(e => e.jobID);
        const result = await runJobsApi(jobIDs)
        stopLoading();
        if (result) {
            setResponseMessage("[Success] System is running Job(s)");
        } else {
            setResponseMessage("[ERROR] System failed to run Job(s), please contact administrator for more information");
        }
        toggleModal(true);

    }

    return (
        <>
            <div className="page-header">
                <h3 className="page-title">Bifrost Configuration List</h3>
            </div>
            <hr/>

            <div className="grid-wrapper" style={{width: "100%", height: "100%"}}>
                <div style={{width: '100%', height: '550px', marginTop: '-15px', marginBottom: '20px'}}
                     className="ag-theme-alpine-dark">
                    <AgGridReact ref={gridRef} rowData={configurationData} columnDefs={columnDefs} pagination={true}
                                 paginationPageSize={10} animateRows={true} multiSortKey={'ctrl'}
                                 onGridReady={params => onGridReady(params)}
                                 onFirstDataRendered={params => onFirstDataRendered(params)}
                                 stopEditingWhenCellsLoseFocus={true} rowSelection="multiple"
                                 enableCellTextSelection={true}
                                 alwaysShowHorizontalScroll={true} alwaysShowVerticalScroll={true}
                                 suppressRowClickSelection={true}
                                 onSelectionChanged={detectFilterCheckedRow}
                                 onFilterChanged={() => onFilterChange()}
                                 autoSizeStrategy={autoSizeStrategy}
                    />
                </div>

                <div className="group-button">
                    <Button
                        variant="contained"
                        startIcon={<RefreshIcon />}
                        onClick={fetchData}>
                        Refresh Data
                    </Button>

                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<ImportIcon />}
                        onClick={openImportModal}>
                        Import Job
                    </Button>

                    {
                        showDeleteButton &&
                        <Button
                            variant="contained"
                            color="info"
                            startIcon={<RunningIcon />}
                            onClick={runJobs}>
                            Run Jobs
                        </Button>
                    }

                    {
                        buttonClearFilter &&
                        <Button
                            variant="contained"
                            startIcon={<FilterListOffIcon />}
                            onClick={() => gridRef.current.api.setFilterModel(null)}>
                            Clear All Filters
                        </Button>
                    }

                    {
                        showDeleteButton &&
                        <Button
                            onClick={() => setOpenModal(true)}
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon/>}>
                            Delete
                        </Button>
                    }

                    <ConfirmModal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        onConfirm={onConfirmDelete}
                        title="Confirmation"
                        message="Are you sure you want to delete selected records?"
                    />

                    <InfoModal
                        open={open}
                        onClose={() => toggleModal(false)}
                        title="Response"
                        message={responseMessage}
                    />
                </div>
            </div>
            <LoadingScreen isLoading={loading}/>

            <ConfigurationEditFrom open={openEditDialog} data={editDialogData}
                                   close={handleCloseEditModal} handleSubmitResult={handleEditConfigResponse}/>

            <ImportJob open={importJobModalOpen} handleClose={closeImportModal} handImportAllResult={handImportAllResult}/>
        </>

    );
};

import "@patternfly/react-core/dist/styles/base.css";

import React, { useEffect, useState } from 'react';
import { BlobServiceClient } from "@azure/storage-blob";
import BlobDataTable from "./BlobDataTable";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Toolbar, ToolbarItem, ToolbarContent, SearchInput, Button, SplitItem, isValidDate, yyyyMMddFormat } from '@patternfly/react-core';
import { DatePicker } from '@patternfly/react-core';
import { SyncAltIcon } from '@patternfly/react-icons'
import { UploadIcon } from '@patternfly/react-icons'
import { TrashIcon } from '@patternfly/react-icons'
import { TableEmptyState } from "./TableEmptyState";
import { Pagination, PaginationVariant } from '@patternfly/react-core';

const { v4: uuidv4 } = require('uuid');

export const ListBlobs = (props) => {
    var rows = []

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [from, setFrom] = React.useState();
    const [to, setTo] = React.useState();
    const [refreshDisabled, setRefreshDisabled] = useState(false);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [sasToken, setSasToken] = useState();


    const toValidator = (date) =>
        isValidDate(start) && date >= start ? '' : 'The "to" date must be after the "from" date';

    const onFromChange = (_event, _value, date) => {
        console.log(date)
        setStart(new Date(date))
        setFrom(yyyyMMddFormat(date))

    };

    const onToChange = (_event, _value, date) => {
        console.log(date)
        setEnd(new Date(date))
        setTo(yyyyMMddFormat(date))

    };

    const [selectedNodeNames, setSelectedNodeNames] = React.useState([]);

    const [data, setData] = useState();
    const [skeleton, setSkeleton] = useState(<>
        <Stack spacing={1.5}>
            <Skeleton variant="rectangular" height={40} sx={{ bgcolor: "grey.900" }} width="100%" />
        </Stack>
    </>);

    React.useEffect(() => {

        async function leaseSas() {
            const headers = new Headers();

            const options = {
                method: "GET",
                headers: headers
            };

            const code = process.env.REACT_APP_FUNCTION_GROUND_TEST_LEASE_SAS_CODE;
            const res = await fetch(`${process.env.REACT_APP_FUNCTION_GROUND_TEST_LEASE_SAS_URI}?code=${code}`, options);
            const status = res.status
            const signature = await res.text()
            if (status === 200) {
                //console.log(signature)
                setSasToken(signature)
                fetchData(signature)

            }
            else {
            }

        }

        leaseSas()

    }, [])

    const defaultPerPage = 10;
    const [paginatedRows, setPaginatedRows] = React.useState();

    const [perPage, setPerPage] = React.useState(defaultPerPage);
    const [page, setPage] = React.useState(1);
    const handleSetPage = (_evt, newPage, _perPage, startIdx, endIdx) => {

        var x = data.slice(startIdx, endIdx)
        setPaginatedRows(x);
        setPage(newPage);
        console.log(x)
    };

    const handlePerPageSelect = (_evt, newPerPage, newPage, startIdx, endIdx) => {
        var x = data.slice(startIdx, endIdx)
        setPaginatedRows(x);
        setPage(newPage);
        setPerPage(newPerPage);


        console.log(x)
    };

    const fetchData = async (sas) => {
        rows = []
        setData()
        setPaginatedRows()
        var containerClient = getBlobClient(sas);
        await listBlobHierarchical(containerClient, props.airline.toUpperCase())
        setData(rows)
        setPaginatedRows(rows.slice(0, defaultPerPage))
        setSkeleton()
        props.setFolders(rows)
    }


    const getBlobClient = (sas) => {
        // Update <placeholder> with your Blob service SAS URL string. Using test storage as fixed value for now.
        const blobSasUrl = `https://fdagroundstorage.blob.core.windows.net`;

        // Create a new BlobServiceClient
        const blobServiceClient = new BlobServiceClient(
            `${blobSasUrl}?${sas}`,
            null
        );

        // Create a unique name for the container by 
        // appending the current time to the file name
        const containerName = "airline-flight-data-temp";

        // Get a container client from the BlobServiceClient
        const containerClient = blobServiceClient.getContainerClient(containerName);
        return containerClient;
    }

    // Recursively list virtual folders and blobs
    // Pass an empty string for prefixStr to list everything in the container
    const listBlobHierarchical = async (containerClient, prefixStr) => {
        // page size - artificially low as example
        const maxPageSize = 5000;

        // some options for filtering list
        const listOptions = {
            includeMetadata: false,
            includeSnapshots: false,
            includeTags: false,
            includeVersions: false,
            prefix: prefixStr
        };

        let delimiter = '/';
        let i = 1;

        for await (const response of containerClient
            .listBlobsByHierarchy(delimiter, listOptions)
            .byPage({ maxPageSize })
        ) {

            const segment = response.segment;

            if (segment.blobPrefixes) {

                // Do something with each virtual folder
                for await (const prefix of segment.blobPrefixes) {
                    // build new prefix from current virtual folder
                    var obj = {

                        id: uuidv4(),
                        name: prefix.name,
                        size: "",
                        tier: "",
                        lastModified: "",
                        state: "",
                        type: "directory",
                        files: {}


                    }
                    rows.push(obj);
                    await listBlobHierarchical(containerClient, prefix.name);
                }
            }

            var obj = []

            for (const blob of response.segment.blobItems) {
                obj.push({
                    id: uuidv4(),
                    name: blob.name,
                    size: blob.properties.contentLength,
                    tier: blob.properties.accessTier,
                    lastModified: blob.properties.lastModified,
                    state: blob.properties.leaseState,
                    type: blob.properties.contentType
                })
            }

            try {
                let objIndex = rows.findIndex(obj => obj.name == prefixStr);
                rows[objIndex].files = obj
            }
            catch (ex) {
                console.log(ex)
            }
            // Do something with each blob
        }

    }

    const onDateFilter = () => {
        filterDate(start, end)
    }

    const onInputSearch = async (e, value) => {
        setTo()
        setFrom()
        var filteredArray = data
            .filter(dir => dir.files
                .some(file => file.name.toLowerCase().includes(value))
            )
            .map(dir => {
                let n = Object.assign({}, dir, {
                    'files': dir.files.filter(
                        file => file.name.toLowerCase().includes(value)
                    )
                })
                return n;
            })
        //console.log(filteredArray)
        if (filteredArray)
            setPaginatedRows(filteredArray)
        else
            setPaginatedRows([])

    }

    const filterDate = (from, to) => {
        var filteredArray = data
            .filter(dir => dir.files
                .some(file => file.lastModified > from && file.lastModified < to)
            )
            .map(dir => {
                let n = Object.assign({}, dir, {
                    'files': dir.files.filter(
                        file => file.lastModified > from && file.lastModified < to
                    )
                })
                return n;
            })
        //console.log(filteredArray)
        if (filteredArray)
            setPaginatedRows(filteredArray)
        else
            setPaginatedRows([])
    }
    const refresh = async () => {
        setRefreshDisabled(true)
        await fetchData(sasToken)
        setTimeout(() => {
            setRefreshDisabled(false)
        }, 1000);
        setFrom()
        setTo()
    }

    const deleteRow = async () => {
        console.log(selectedNodeNames)
        alert("Can't delete, you don't have permission or scope is invalid")
        //setData()
    }

    const items = <React.Fragment>
        <ToolbarItem>
            <Button variant="primary" className='pf-v5-theme-dark' onClick={props.handleModal} icon={<UploadIcon />} ouiaId="PrimaryWithIcon">
                Upload File
            </Button>
        </ToolbarItem>

        <ToolbarItem variant="search-filter">
            <SearchInput aria-label="Items example search input" onChange={onInputSearch} />
        </ToolbarItem>
        <ToolbarItem>
            <SplitItem style={{ padding: '6px 12px 0 12px', color: "#666" }}>|</SplitItem>
        </ToolbarItem>
        <ToolbarItem>
            <DatePicker value={from} onChange={onFromChange} aria-label="Start date" placeholder="YYYY-MM-DD" />
        </ToolbarItem>

        <ToolbarItem>
            <DatePicker
                value={to}
                onChange={onToChange}
                isDisabled={!isValidDate(start)}
                rangeStart={start}
                validators={[toValidator]}
                aria-label="End date"
                placeholder="YYYY-MM-DD"
            />
        </ToolbarItem>
        <ToolbarItem>
            <Button variant="primary" className='pf-v5-theme-dark' onClick={onDateFilter} ouiaId="PrimaryWithIcon">
                Filter
            </Button>
        </ToolbarItem>
        <ToolbarItem>
            <SplitItem style={{ padding: '6px 12px 0 12px', color: "#666" }}>|</SplitItem>
        </ToolbarItem>
        <ToolbarItem>
            <Button variant="control" style={{ color: "#00d25b" }} aria-label="Refresh" isDisabled={refreshDisabled} onClick={refresh}>
                <SyncAltIcon />
            </Button>
        </ToolbarItem>



    </React.Fragment>


    const renderPagination = (variant = 'top') => <Pagination className='pf-v5-theme-dark' isCompact itemCount={data.length} page={page} perPage={perPage} isLastFullPageShown onSetPage={handleSetPage} onPerPageSelect={handlePerPageSelect} perPageOptions={[{
        title: '3',
        value: 3
    }, {
        title: '5',
        value: 5
    }, {
        title: '12',
        value: 12
    }, {
        title: '20',
        value: 20
    }]} titles={{
        paginationAriaLabel: `${variant} pagination`
    }} />;

    return (
        <>
            <Toolbar className='pf-v5-theme-dark' id="toolbar-items-example">
                <ToolbarContent className='pf-v5-theme-dark'>{items}</ToolbarContent>
            </Toolbar>
            {skeleton}
            {
                data && paginatedRows ?
                    <>
                        <BlobDataTable data={paginatedRows} theme={props.theme} setSelectedNodeNames={setSelectedNodeNames} ></BlobDataTable>
                        {renderPagination()}
                    </>
                    :
                    <TableEmptyState></TableEmptyState>
            }

        </>
    );
};
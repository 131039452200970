import axios from "axios";
import {MessageCode, ResponseStatus} from "../constants/api.constants.js";

const cleanJobConfiguration = async (jobIDList) => {
    const apiUrl = process.env.REACT_APP_API_CLEAN_JOB_CONFIGURATION;
    let result;
    try {
        const axiosResponse = await axios.delete(apiUrl, {data: {jobIDList}});
        const {status, messageCode} = axiosResponse.data;
        result = status === ResponseStatus.SUCCESS && messageCode === MessageCode.SUCCESS;
    } catch (error) {
        result = false;
    }
    return result;
};
export default cleanJobConfiguration;

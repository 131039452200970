import axios from "axios";
import {MessageCode, ResponseStatus} from "../constants/api.constants.js";

const editJobConfiguration = async (body) => {
    const apiUrl = process.env.REACT_APP_API_EDIT_JOB;
    let result;
    try {
        const axiosResponse = await axios.post(apiUrl, body);
        const {status, messageCode, data} = axiosResponse.data;
        result = status === ResponseStatus.SUCCESS && messageCode === MessageCode.SUCCESS ? data : false;
    } catch (error) {
        result = false;
    }
    return result;
};
export default editJobConfiguration;

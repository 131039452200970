import React from 'react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';
import { Bullseye, EmptyState, EmptyStateVariant, EmptyStateIcon, EmptyStateBody, Button, EmptyStateHeader, EmptyStateFooter, EmptyStateActions } from '@patternfly/react-core';
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';

const columnNames = {
    name: 'Name',
    size: 'Size',
    type: 'Type',
    tier: 'Tier',
    state: 'State',
    lastModified: 'Last Modified'
};

export const TableEmptyState = () =>
    <Table className='pf-v5-theme-dark' aria-label="Empty state table">
        <Thead>
            <Tr>
            <Th width={40}>{columnNames.name}</Th>
                    <Th>{columnNames.size}</Th>
                    <Th>{columnNames.type}</Th>
                    <Th>{columnNames.tier}</Th>
                    <Th>{columnNames.state}</Th>
                    <Th>{columnNames.lastModified}</Th>
            </Tr>
        </Thead>
        <Tbody>
            <Tr>
                <Td colSpan={8}>
                    <Bullseye>
                        <EmptyState variant={EmptyStateVariant.sm}>
                            <EmptyStateHeader icon={<EmptyStateIcon icon={SearchIcon} />} titleText="No results found" headingLevel="h2" />
                            <EmptyStateBody>Clear all filters and try again.</EmptyStateBody>
                            <EmptyStateFooter>
                                <EmptyStateActions>
                                    {/*<Button variant="link">Clear all filters</Button>*/}
                                </EmptyStateActions>
                            </EmptyStateFooter>
                        </EmptyState>
                    </Bullseye>
                </Td>
            </Tr>
        </Tbody>
    </Table>;
import {useContext, useEffect, useState} from "react";
import {LoadingContext} from "./providers/loading.provider.js";
import getAllAirline from "./api-helpers/getAllAirline.api.js";
import * as Yup from "yup";
import {ErrorMessage, Formik} from "formik";
import Form from "react-bootstrap/Form";
import './css/Form.css';
import importJobApi from "./api-helpers/importJob.api.js";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const ImportAllForm = ({show, closeImportModal, handImportAllResult}) => {
    const {startLoading, stopLoading} = useContext(LoadingContext);
    const [airlineIds, setAirlineIdsIds] = useState([]);
    const [isFetchData, setIsFetchData] = useState(false);
    const [initialValues, setInitialValues] = useState({airlineID: '', dateRange: 30});


    const fetchResource = async () => {
        try {
            // Make API requests to fetch dropdown data
            startLoading();
            setIsFetchData(false);
            const responses = await getAllAirline();
            setAirlineIdsIds([{ icaoCode: '', airlineName: 'Select an airline' }, ...responses?.data]);
            stopLoading();
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        } finally {
            setIsFetchData(true);
        }
    };

    useEffect(() => {
        if (show) {
            fetchResource().then(() => {});
        }
    }, []);

    const validationSchema = Yup.object({
        airlineID: Yup.string().required('Airplane ID is required'),
        dateRange: Yup.number()
            .typeError('Date Range must be a number')
            .min(1, 'Date Range must be greater than 0')
            .max(365, 'Date Range must be at most 365 days')
            .required('Date Range is required'),
    });

    const clickButtonSubmit = async (formValid, values) => {
        if (!formValid) {
            return;
        }
        const body = {
            airlineCode: values?.airlineID,
            dateRange: values?.dateRange,
        }

        startLoading();
        const result = await importJobApi(body).then();
        stopLoading();
        // inform parent comps
        handImportAllResult(result);
    }


    if (!show || !isFetchData) {
        return null;
    }
    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                }}>
                {props => (
                    <Form className="bifrost-form-container">
                        <div className={'bifrost-form-control'}>
                            <Autocomplete
                                          id="airlineAutocomplete"
                                          value={
                                              airlineIds.find(e => e?.icaoCode === props.values.airlineID) || null
                                          }
                                          options={airlineIds}
                                          getOptionLabel={(option) => option.airlineName}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  label="Airline ID *"
                                                  name="airlineID"
                                                  InputProps={{
                                                      ...params.InputProps,
                                                      classes: {input: 'bifrost-customInput'},
                                                  }}
                                                  InputLabelProps={{className: 'bifrost-form-label'}}
                                              />
                                          )}
                                          onChange={(e, value) => {
                                              props.setFieldValue('airlineID', value?.icaoCode).then();
                                          }}
                                          classes={{option: 'bifrost-full-control'}}
                            />
                            <ErrorMessage component="div" name="airlineID" className="bifrost-error-message"/>
                        </div>
                        {/* airlineID field */}

                        <div className={'bifrost-form-control'}>
                            <TextField id="dateRange"
                                       label="Date Range *"
                                       fullWidth={true}
                                       color={props.touched.dateRange && Boolean(props.errors.dateRange) ? 'error' : 'primary'}
                                       name="dateRange"
                                       value={props.values.dateRange}
                                       onChange={props.handleChange}
                                       onBlur={props.handleBlur}
                                       error={props.touched.dateRange && Boolean(props.errors.dateRange)}
                                       variant="outlined"
                                       disabled={!props.values.airlineID}
                                       InputProps={{
                                           classes: {input: 'bifrost-customInput'},
                                       }}
                                       InputLabelProps={{className: 'bifrost-form-label'}}
                            />
                            <ErrorMessage component="div" name="dateRange"
                                          className="bifrost-error-message"/>
                        </div>{/* Date Range field */}

                        <hr/>
                        <div className="bifrost-form-button-group">
                            <button onClick={() => clickButtonSubmit(props.isValid, props.values)}
                                    disabled={!props.isValid || !props.values.airlineID} className="bifrost-submit-btn"
                                    type="button">Submit
                            </button>
                            <button onClick={closeImportModal} className="bifrost-cancel-btn" type="button">Cancel
                            </button>
                        </div>
                    </Form>
                )}

            </Formik>
        </div>
    )
}

export default ImportAllForm;

import React, { Component, useState, useEffect } from 'react';
import { Line, Bar, Doughnut, Pie, Scatter, Polar, Radar, Bubble } from 'react-chartjs-2';
import { ListTails } from "../components/ListTails";
import Spinner from 'react-bootstrap/Spinner';
import { ListPreferences } from '../components/ListPreferences';
import { ListTriggers } from '../components/ListTriggers';
import { ListFlightProgress } from '../components/ListFlightProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import moment from 'moment'

export const Dashboard = (props) => {

  const [TSP, setTSP] = useState(<>
    <Stack spacing={1.5}>
      <Skeleton variant="text" width="60%" sx={{ bgcolor: "grey.900" }} />
    </Stack>
  </>);
  const [token, setToken] = useState();
  const [flightSource, setFlightSource] = useState(true);
  const [lastFlight, setLastFlight] = useState(true);
  const [dbConnectionOK, setDbConnectionOK] = useState(true);
  const [labels, setLabels] = useState([]);
  const [averageFuelPercent, setAverageFuelPercent] = useState([]);
  const [averageFuelKg, setAverageFuelKg] = useState([]);
  const [totalFuelKg, setTotalFuelKg] = useState([]);
  const [totalTime, setTotalTime] = useState([]);
  const [date, setDate] = useState();
  const [totalCarbonEmission, setTotalCarbonEmission] = useState([]);

  useEffect(() => {
    getTSPAF();
    getAirlineStatus();
    getSavingsReports();
  }, []);

  const getTSP = async () => {
    //const token = await login();
    const headers = new Headers();
    const bearer = `Bearer ${props.token}`;

    headers.append("Authorization", bearer);
    headers.append("Airline", `airline-${props.airline}`);
    headers.append("Ocp-Apim-Subscription-Key", `${process.env.REACT_APP_APIM_KEY}`);

    var requestOptions = {
      method: "GET",
      headers: headers
    };
    console.log(requestOptions)

    fetch(process.env.REACT_APP_TSP_GET_URI, requestOptions)
      .then(response => response.text())
      .then(data => {
        if (data != "") {
          setTSP(data);
        }
        else {
          setTSP("TSP Not Found");
        }
      }
      )
      .catch(error => console.log('error', error));
  }

  const getAirlineStatus = async () => {
    //const token = await login();
    const headers = new Headers();
    const bearer = `Bearer ${props.token}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("Ocp-Apim-Subscription-Key", `${process.env.REACT_APP_APIM_KEY}`);

    var requestOptions = {
      method: "GET",
      headers: headers
    };

    fetch(`${process.env.REACT_APP_AIRLINE_STATUS_GET_URI}?airline=${props.airline}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        try {
          if (data[1].content[0].source.airline = !undefined) {
            setFlightSource(true);
          }
          else {
            setFlightSource(false);
          }
        }
        catch (error) {
          setFlightSource(false);
        }

        try {
          if (data[1].content[1].lastFlight[0].id = !undefined) {
            setLastFlight(true);
          }
          else {
            setLastFlight(false);
          }
        }
        catch (error) {
          setLastFlight(false);
        }

        if (data[2].status == "OK") {
          setDbConnectionOK(true);
        }
        else {
          setDbConnectionOK(false);
        }
      }
      )
      .catch(error => console.log('error', error));
  }

  const getTSPAF = async () => {

    const code = process.env.REACT_APP_FUNCTION_TSP_GET_CODE;
    fetch(`${process.env.REACT_APP_FUNCTION_TSP_GET_URI}?code=${code}&airline=${props.airline}`)
      .then(response => response.text())
      .then(data => {
        if (data != "") {
          setTSP(data);
        }
        else {
          setTSP("TSP Not Found");
        }
      }
      )
      .catch(error => console.log('error', error));
  }

  const [color, setColor] = useState(
    ['rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)']
  );
  const [colorBorder, setColorBorder] = useState(
    ['rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)']
  );

  const getSavingsReports = async () => {
    const code = process.env.REACT_APP_FUNCTION_CAPTUREDSAVINGS_GET_CODE;
    var requestOptions = {
      method: "GET",
      headers: {
        "x-functions-key": `${code}`
      }
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_CAPTUREDSAVINGS_GET_URI}`, requestOptions);
      let status = await res.status;
      let data = await res.json();
      if (status == 200) {
        let labels = [];
        let averageFuelPercent = [];
        let averageFuelKg = [];
        let totalFuelKg = [];
        let totalTime = [];
        let date = [];
        let totalCarbonEmission = [];

        data.forEach(pair => {
          labels.push(pair.ID);
          averageFuelPercent.push(pair.AverageFuelPercent);
          averageFuelKg.push(pair.AverageFuelKg);
          totalFuelKg.push(pair.TotalFuel);
          totalTime.push(pair.TotalTime);
          date.push(pair.Date);
          totalCarbonEmission.push(pair.TotalCarbonEmission);
          dynamicColors();
        });
        setLabels(labels);
        setAverageFuelPercent(averageFuelPercent);
        setAverageFuelKg(averageFuelKg);
        setTotalFuelKg(totalFuelKg);
        setTotalTime(totalTime);
        setDate(date.pop());
        setTotalCarbonEmission(totalCarbonEmission);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const dynamicColors = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    let x = "rgba(" + r + ", " + g + ", " + b + ", 0.2)";
    let y = "rgba(" + r + ", " + g + ", " + b + ", 1)";
    //setColor(x => [...x, color]);
    //setColorBorder(x => [...x, border]);
    color.push(x);
    colorBorder.push(y)
  };


  const barData = {
    labels: labels,
    datasets: [{
      label: 'Kg Avg Fuel',
      data: averageFuelKg,
      backgroundColor: color,
      borderColor: colorBorder,
      borderWidth: 1,
      fill: false
    }]
  };

  const options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          color: "rgba(204, 204, 204,0.1)"
        }
      }],
      xAxes: [{
        gridLines: {
          color: "rgba(204, 204, 204,0.1)"
        }
      }]
    },
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 0
      }
    }
  }

  const polarData = {
    labels: labels,
    datasets: [{
      label: '% Avg Fuel',
      data: averageFuelPercent,
      backgroundColor: color,
      borderColor: colorBorder,
      borderWidth: 1,
      fill: false
    }]
  };

  const polarOptions = {
    scales: {

    },
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 0
      }
    }
  }

  const barTotalData = {
    labels: labels,
    datasets: [{
      label: 'Kg Total Fuel',
      data: totalFuelKg,
      backgroundColor: color,
      borderColor: colorBorder,
      borderWidth: 1,
      fill: false
    }]
  };

  const barTotalTimeData = {
    labels: labels,
    datasets: [{
      label: 'Total Time',
      data: totalTime,
      backgroundColor: color,
      borderColor: colorBorder,
      borderWidth: 1,
      fill: false
    }]
  };

  const areaTotalCarbonEmission = {
    labels: labels,
    datasets: [{
      label: 'Total Time',
      data: totalCarbonEmission,
      backgroundColor: [
        'rgba(0, 210, 91, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(0, 210, 91, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1,
      fill: true
    }]
  };

  const areaOptions = {
    plugins: {
      filler: {
        propagate: true
      }
    },
    scales: {
      yAxes: [{
        gridLines: {
          color: "rgba(204, 204, 204,0.1)"
        }
      }],
      xAxes: [{
        gridLines: {
          color: "rgba(204, 204, 204,0.1)"
        }
      }]
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-xl-4 col-sm-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex align-items-center align-self-start">
                    <h3 className="mb-0">TSP</h3>
                    <p className="text-success ml-2 mb-0 font-weight-medium">newest</p>
                  </div>
                </div>
                <div className="col-3">
                  <div className="icon icon-box-success ">
                    <span className="mdi mdi-new-box icon-item"></span>
                  </div>
                </div>
              </div>
              <h6 className="text-muted font-weight-normal">{TSP}
              </h6>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6 grid-margin stretch-card">
          <div className="card">
            {
              dbConnectionOK
                ?
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h3 className="mb-0">DB Status</h3>
                        <p className="text-info ml-2 mb-0 font-weight-medium"></p>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-success ">
                        <span className="mdi mdi mdi-database icon-item"></span>
                      </div>
                    </div>
                  </div>
                  <h6 className="text-muted font-weight-normal">
                    UP <i className='mdi mdi-check icon-item text-success'> </i>
                  </h6>
                </div>
                :
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h3 className="mb-0">Database</h3>
                        <p className="text-info ml-2 mb-0 font-weight-medium"></p>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-danger ">
                        <span className="mdi mdi-database-alert icon-item"></span>
                      </div>
                    </div>
                  </div>
                  <h6 className="text-muted font-weight-normal">
                    Offline <i className='mdi mdi-alert-circle icon-item text-danger'> </i>
                  </h6>
                </div>
            }
          </div>
        </div>
        {<div className="col-xl-4 col-sm-6 grid-margin stretch-card">
          <div className="card">
            {
              flightSource && lastFlight
                ?
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h3 className="mb-0">Flight Plans</h3>
                        <p className="text-info ml-2 mb-0 font-weight-medium"></p>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-success ">
                        <span className="mdi mdi-check-circle icon-item"></span>
                      </div>
                    </div>
                  </div>
                  <h6 className="text-muted font-weight-normal">
                    Source <i className='mdi mdi-check icon-item text-success'> </i>
                    DB Connection <i className='mdi mdi-check icon-item text-success'></i>
                  </h6>
                </div>
                :
                <div className="card-body">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center align-self-start">
                        <h3 className="mb-0">Flight Plans</h3>
                        <p className="text-info ml-2 mb-0 font-weight-medium"></p>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="icon icon-box-danger ">
                        <span className="mdi mdi-alert-circle icon-item"></span>
                      </div>
                    </div>
                  </div>
                  <h6 className="text-muted font-weight-normal">
                    Source
                    {
                      flightSource
                        ?
                        <i className='mdi mdi-check icon-item text-success'> </i>
                        :
                        <i className='mdi mdi-close icon-item text-danger'> </i>
                    }
                    DB Connection
                    {
                      lastFlight
                        ?
                        <i className='mdi mdi-check icon-item text-success'> </i>
                        :
                        <i className='mdi mdi-close icon-item text-danger'> </i>
                    }
                  </h6>
                </div>
            }
          </div>
        </div>}
      </div>
      {
        props.airline == "fda"
          ?
          <><div className="row">
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-row justify-content-between">
                    <h4 className="card-title mb-1">Avg Fleet Savings (Kg)</h4>
                    <p className="text-muted mb-1">{moment(date).format('MMMM Do YYYY')} - Present</p>
                  </div>
                  <br></br>
                  <div className="row">

                    <Bar data={barData} options={options} />

                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-row justify-content-between">
                    <h4 className="card-title mb-1">Avg Fleet Savings (%):</h4>
                    <p className="text-muted mb-1">{moment(date).format('MMMM Do YYYY')} - Present</p>
                  </div>
                  {
                    /*
    
                    props.groupId
                      ?
              
                      <ListTails groupId={props.groupId} token={props.token} airline={props.airline} />
                      :
                      <div></div>
                      */
                    <Polar data={polarData} />
                  }

                </div>
              </div>
            </div>

          </div><div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-row justify-content-between">
                      <h4 className="card-title mb-1">Total Fleet Savings (Kg)</h4>
                      <p className="text-muted mb-1">{moment(date).format('MMMM Do YYYY')} - Present</p>
                    </div>
                    <br></br>
                    <div className="row">
                      <Bar data={barTotalData} options={options} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-row justify-content-between">
                      <h4 className="card-title mb-1">Total Time Savings</h4>
                      <p className="text-muted mb-1">{moment(date).format('MMMM Do YYYY')} - Present</p>
                    </div>
                    <br></br>
                    <div className="row">
                      <Bar data={barTotalTimeData} options={options} />
                    </div>
                  </div>
                </div>
              </div>
            </div><div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-row justify-content-between">
                      <h4 className="card-title mb-1">Total Carbon Emission Savings (Kg)</h4>
                      <p className="text-muted mb-1">{moment(date).format('MMMM Do YYYY')} - Present</p>
                    </div>
                    <br></br>
                    <div className="row">
                      <Line data={areaTotalCarbonEmission} options={areaOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          <></>
      }
    </div>
  );

}

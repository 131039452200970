import axios from "axios";
import {handleSingleResponse, handleSuccessResponse, RETRY_TIMES, TIMEOUT} from "./api.helper.js";

const apiGetAllAirlineCodeTypeUrl = process.env.REACT_APP_API_GET_ALL_AIRLINE_CODE_TYPE;

const getAllAirlineCodeType = async (option) => {
    const timeout = option?.timeout || TIMEOUT;
    const retryTimes = option?.retryTimes || RETRY_TIMES;
    let count = 1;
    let succeededResponse = null;
    try {
        // try call api at the first time.
        succeededResponse = await axios.get(apiGetAllAirlineCodeTypeUrl, {timeout});
        return handleSuccessResponse(succeededResponse);
    } catch (e) {
        // as axios will throw an error in case of timeout, this try-catch block
        // if the first time api call error, try again.
        let result = null;
        while (retryTimes > count) {
            result = await handleSingleResponse(axios.get(apiGetAllAirlineCodeTypeUrl));
            if (result?.success === true) {
                break;
            } else {
                count++
            }
        }
        return result;
    }
};
export default getAllAirlineCodeType;

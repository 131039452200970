import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Modal from "react-bootstrap/Modal";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MenuItem from '@mui/material/MenuItem';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment'
import { Chip, DialogContent, FormHelperText, Input } from "@mui/material";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import Box from '@mui/material/Box';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Check, DoNotDisturb } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import { pink } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import UpdateIcon from '@mui/icons-material/Update';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import { Airlines } from "@mui/icons-material";
import StorageIcon from '@mui/icons-material/Storage';
import GetAppIcon from '@mui/icons-material/GetApp';
import FunctionsIcon from '@mui/icons-material/Functions';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import EventIcon from '@mui/icons-material/Event';
import { useSnackbar } from 'notistack';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import { TabContext } from '@mui/lab';
import DataArrayIcon from '@mui/icons-material/DataArray';
import HistoryIcon from '@mui/icons-material/History';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReportingDataTable from "./ReportingDataTable";
import TSPCalibrationDataTable from "./TSPCalibrationDataTable";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SavingsReports } from "./SavingsReports";
import { AggregatedData } from "./AggregatedData";
import TodayIcon from '@mui/icons-material/Today';
import Autocomplete from '@mui/material/Autocomplete';
import CommentBox from "./CommentBox";

export const FleetCellRenderer = (props) => {
  const [show, setShow] = useState(false);
  const [sendLabel, setSendLabel] = useState("Send");
  const [sendDisable, setSendDisable] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertFail, setShowAlertFail] = useState(false);
  const [lastMeeting, setLastMeeting] = useState(props.data.LastMeeting);
  const [currValue, setCurrValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  //Fleet variables
  const [fleetID, setFleetID] = useState(props.data.FleetID);
  const [reportReminderID, setReportReminderID] = useState(props.data.ReportReminderID);
  const [contractID, setContractID] = useState(props.data.ContractID);
  const [daysBeforeDue3, setDaysBeforeDue3] = useState(props.data.DaysBeforeDue3);
  const [daysBeforeDue7, setDaysBeforeDue7] = useState(props.data.DaysBeforeDue7);
  const [daysBeforeDue14, setDaysBeforeDue14] = useState(props.data.DaysBeforeDue14);
  const [lastSent, setLastSent] = useState(props.data.LastSentAsString);
  const [reportLastSent, setReportLastSent] = useState();
  const [calibrationLastSent, setCalibrationLastSent] = useState();
  const [fleetName, setFleetName] = useState(props.data.FleetName),
    onInputFleetName = ({ target: { value } }) => {
      setFleetName(value);
    };
  const [reportingFrequency, setReportingFrequency] = useState(props.data.Frequency),
    onInputReportingFrequency = ({ target: { value } }) => {
      setReportingFrequency(value);
    }
  const [modelID, setModelID] = useState(props.data.Model),
    onInputModelID = ({ target: { value } }) => {
      setModelID(value);
    };
  const [defaultCI, setDefaultCI] = useState(props.data.DefaultCI),
    onInputDefaultCI = ({ target: { value } }) => {
      setDefaultCI(value);
    }
  const [dataSource, setDataSource] = useState(props.data.DataSource),
    onInputDataSource = ({ target: { value } }) => {
      setDataSource(value);
    }
  const [dataReceived, setDataReceived] = useState(props.data.DataReceived),
    onInputDataReceived = ({ target: { value } }) => {
      setDataReceived(value);
    }
  const [deployment, setDeployment] = useState(props.data.Deployment),
    onInputDeployment = ({ target: { value } }) => {
      setDeployment(value);
    }
  const [isContract, setIsContract] = useState(props.data.IsContract),
    onInputIsContract = ({ target: { value } }) => {
      setIsContract(value);
    }
  const [isActive, setIsActive] = useState(props.data.IsActive),
    onInputIsActive = ({ target: { value } }) => {
      setIsActive(value);
    }
  const [expirationDate, setExpirationDate] = useState(props.data.ExpirationDateAsString),
    handleExpirationDate = (newValue) => {
      setExpirationDate(moment(newValue).format("YYYY-MM-DD"));
    };
  const [trialStartDate, setTrialStartDate] = useState(props.data.TrialStartDate),
    handleTrialStartDate = (newValue) => {
      setTrialStartDate(moment(newValue).format("YYYY-MM-DD"));
    };
  const handleDaysBeforeDue3 = (e) => {
    setDaysBeforeDue3(e.target.checked);
  };
  const handleDaysBeforeDue7 = (e) => {
    setDaysBeforeDue7(e.target.checked);
  };
  const handleDaysBeforeDue14 = (e) => {
    setDaysBeforeDue14(e.target.checked);
  };
  const [reportingEnabled, setReportingEnabled] = useState();
  const [reportReminderEnabled, setReportReminderEnabled] = useState();


  //TSP Calibration variables
  const [tspCalibrationReminderID, setTspCalibrationReminderID] = useState();
  const [tspCalibrationFrequency, setTspCalibrationFrequency] = useState(),
    onInputCalibrationFrequency = ({ target: { value } }) => {
      setTspCalibrationFrequency(value);
    }
  const [thresholdDays3, setThresholdDays3] = useState();
  const [thresholdDays7, setThresholdDays7] = useState();
  const [thresholdDays14, setThresholdDays14] = useState();
  const [tspCalibrationEnabled, setTspCalibrationEnabled] = useState();
  const [tspCalibrationStartDate, setTspCalibrationStartDate] = useState(),
    handleTspCalibrationStartDate = (newValue) => {
      setTspCalibrationStartDate(moment(newValue).format());
      setCalibrationLastSent(moment(newValue).format());
    };
  const [reportingStartDate, setReportingStartDate] = useState(),
    handleReportingStartDate = (newValue) => {
      setReportingStartDate(moment(newValue).format());
      setReportLastSent(moment(newValue).format());
    };
  const handleThreshold3Days = (e) => {
    setThresholdDays3(e.target.checked);
  };
  const handleThreshold7Days = (e) => {
    setThresholdDays7(e.target.checked);
  };
  const handleThreshold14Days = (e) => {
    setThresholdDays14(e.target.checked);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [reportEventData, setReportEventData] = useState([]);
  const [tspCalibrationEventData, setTspCalibrationEventData] = useState([]);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    props.context.refresh()
  };

  const [reportingEmails, setReportingEmails] = useState([]);
  const [calibrationEmails, setCalibrationEmails] = useState([]);

  const [sasToken, setSasToken] = useState();

  const { BlobServiceClient } = require('@azure/storage-blob');

  const [emails, setEmails] = useState();

  useEffect(() => {
    try {
      getTspCalibrationReminderByID();
      getReportReminderByID();
      leaseSas();
    }
    catch (e) {
      console.log(e);
    }

  }, [modelID]);

  const leaseSas = async () => {
    const headers = new Headers();

    const options = {
      method: "GET",
      headers: headers
    };

    const code = process.env.REACT_APP_FUNCTION_GROUND_LEASE_SAS_CODE;
    const res = await fetch(`${process.env.REACT_APP_FUNCTION_GROUND_LEASE_SAS_URI}?code=${code}`, options);
    const status = res.status
    const signature = await res.text()
    if (status === 200) {
      setSasToken(signature)
      getEmails(signature);

    }
    else {
      console.log(signature)
    }
  }

  const getEmails = async (sas) => {
    const blobSasUrl = `https://${process.env.REACT_APP_GROUND_STORAGE_ACCOUNT}.blob.core.windows.net`;

    // Create a new BlobServiceClient
    const blobServiceClient = new BlobServiceClient(
      `${blobSasUrl}?${sas}`,
      null
    );

    const containerName = `persistent-emails`;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient("tsp-calibration-emails.json");

    // Download and convert a blob to a string
    const downloadBlockBlobResponse = await blobClient.download();
    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
    const json = JSON.parse(downloaded);

    setEmails(json.emails);

  }

  const blobToString = async (blob) => {

    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }


  const getTspCalibrationReminderByID = async () => {
    const code = process.env.REACT_APP_FUNCTION_TSPCALIBRATIONREMINDER_GETBYID_CODE;
    var requestOptions = {
      method: "GET",
      headers: {
        "x-functions-key": `${code}`
      }
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_TSPCALIBRATIONREMINDER_GETBYID_URI}?fleetID=${fleetID}`, requestOptions);
      let status = await res.status;
      let data = await res.json();
      if (status == 200) {
        setTspCalibrationReminderID(data[0].TSPCalibrationReminderID);
        setTspCalibrationFrequency(data[0].Frequency);
        setThresholdDays3(data[0].ThresholdDays3);
        setThresholdDays7(data[0].ThresholdDays7);
        setThresholdDays14(data[0].ThresholdDays14);
        setTspCalibrationEnabled(data[0].IsEnabled);
        setTspCalibrationStartDate(data[0].StartDateAsString);
        setCalibrationLastSent(data[0].LastSentAsString);
        getTspCalibrationEventByID(data[0].TSPCalibrationReminderID);
        console.log("no emails: " + data[0].ReminderEmails)
        if (data[0].ReminderEmails !== "") {
          setCalibrationEmails(data[0].ReminderEmails.split(","));
        }

      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getReportReminderByID = async () => {
    const code = process.env.REACT_APP_FUNCTION_REPORTINGREMINDER_GETBYID_CODE;
    var requestOptions = {
      method: "GET",
      headers: {
        "x-functions-key": `${code}`
      }
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_REPORTINGREMINDER_GETBYID_URI}?fleetID=${fleetID}`, requestOptions);
      let status = await res.status;
      let data = await res.json();
      if (status == 200) {
        setReportReminderID(data[0].ReportReminderID);
        setReportingFrequency(data[0].Frequency);
        setDaysBeforeDue3(data[0].DaysBeforeDue3);
        setDaysBeforeDue7(data[0].DaysBeforeDue7);
        setDaysBeforeDue14(data[0].DaysBeforeDue14);
        setReportReminderEnabled(data[0].IsEnabled);
        if (data[0].ReminderEmails !== undefined) {
          setReportingEmails(data[0].ReminderEmails.split(","));
        }
        setReportingStartDate(data[0].StartDateAsString);
        console.log(data[0].StartDateAsString)
        getReportEventByID(data[0].ReportReminderID);
        setReportLastSent(data[0].LastSentAsString)
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getReportEventByID = async (reportReminderID) => {
    const code = process.env.REACT_APP_FUNCTION_REPORTREMINDEREVENT_GETBYID_CODE;
    var requestOptions = {
      method: "GET",
      headers: {
        "x-functions-key": `${code}`
      }
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_REPORTREMINDEREVENT_GETBYID_URI}?reportReminderID=${reportReminderID}`, requestOptions);
      let status = await res.status;
      let data = await res.json();
      if (status == 200) {
        setReportEventData(data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getTspCalibrationEventByID = async (tspCalibrationReminderID) => {
    const code = process.env.REACT_APP_FUNCTION_TSPCALIBRATIONREMINDEREVENT_GETBYID_CODE;
    var requestOptions = {
      method: "GET",
      headers: {
        "x-functions-key": `${code}`
      }
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_TSPCALIBRATIONREMINDEREVENT_GETBYID_URI}?tspCalibrationReminderID=${tspCalibrationReminderID}`, requestOptions);
      let status = await res.status;
      let data = await res.json();
      if (status == 200) {
        setTspCalibrationEventData(data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleClose = () => {
    setShow(false);
    setShowAlertSuccess(false);
    setShowAlertFail(false);
    //setDisabled(true);
    try {
      setFleetName(props.data.FleetName);
      setDaysBeforeDue3(props.data.DaysBeforeDue3);
      setDaysBeforeDue7(props.data.DaysBeforeDue7);
      setDaysBeforeDue14(props.data.DaysBeforeDue14);
      setModelID(props.data.Model);
      setDefaultCI(props.data.DefaultCI);
      setDataSource(props.data.DataSource);
      setDataReceived(props.data.DataReceived);
      setDeployment(props.data.Deployment);
      setReportingFrequency(props.data.Frequency);
      setLastSent(props.data.LastSent);
      setReportingEmails(props.data.ReminderEmails.split(","));
      setIsActive(props.data.IsActive);
      setIsContract(props.data.IsContract);
      props.context.refresh()
    }
    catch (e) {
      console.log(e);
    }
  };

  const handleShow = () => {
    setShow(true);
    setSendDisable(false);
  };

  const handleLastMeeting = (newValue) => {
    setLastMeeting(moment(newValue).format());
  };

  const updateFleet = () => {
    if (reportReminderEnabled && reportingEmails.length == 0) {
      alert("Emails are empty for Analytics Reporting");
      return;
    }
    if (tspCalibrationEnabled && calibrationEmails.length == 0) {
      alert("Emails are empty for TSP Calibration");
      return;
    }
    updateFleetInfo();
    updateTSPCalibration();
    updateReportReminder();
    //handleClose();
  }

  const updateFleetInfo = async () => {
    const code = process.env.REACT_APP_FUNCTION_FLEET_UPDATE_CODE;
    var body = JSON.stringify({
      "FleetID": `${fleetID}`,
      "ContractID": `${contractID}`,
      "ReportReminderID": `${reportReminderID}`,
      "FleetName": `${fleetName}`,
      "Frequency": `${reportingFrequency}`,
      "DaysBeforeDue3": `${daysBeforeDue3}`,
      "DaysBeforeDue7": `${daysBeforeDue7}`,
      "DaysBeforeDue14": `${daysBeforeDue14}`,
      "IsContract": `${isContract}`,
      "IsActive": `${isActive}`,
      "Deployment": `${deployment}`,
      "ReminderEmails": `${reportingEmails}`,
      "DataSource": `${dataSource}`,
      "DataReceived": `${dataReceived}`,
      "DefaultCI": `${defaultCI}`,
      "Model": `${modelID}`,
      "ExpirationDateAsString": `${expirationDate}`,
      "TrialStartDate": `${trialStartDate}`
    });
    var requestOptions = {
      method: "PUT",
      headers: {
        "x-functions-key": `${code}`
      },
      body: body
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_FLEET_UPDATE_URI}`, requestOptions);
      let status = await res.status;
      let data = await res.text();
      if (status == 204) {
        console.log(data);
        enqueueSnackbar(`Fleet Info updated: ${props.data.FleetName}`, { variant: 'success' });
      }
      else {
        enqueueSnackbar("Error", { variant: 'error' })
      }
    }
    catch (error) {
      enqueueSnackbar("Error", { variant: 'error' })
      console.log(error);
    }
  }

  const updateTSPCalibration = async () => {
    const code = process.env.REACT_APP_FUNCTION_TSPCALIBRATIONREMINDER_UPDATE_CODE;
    var body = JSON.stringify({
      "TSPCalibrationReminderID": `${tspCalibrationReminderID}`,
      "Frequency": `${tspCalibrationFrequency}`,
      "ThresholdDays3": `${thresholdDays3}`,
      "ThresholdDays7": `${thresholdDays7}`,
      "ThresholdDays14": `${thresholdDays14}`,
      "IsEnabled": `${tspCalibrationEnabled}`,
      "StartDateAsString": `${tspCalibrationStartDate}`,
      "ReminderEmails": `${calibrationEmails}`,
      "LastSentAsString": `${calibrationLastSent}`
    });
    var requestOptions = {
      method: "PUT",
      headers: {
        "x-functions-key": `${code}`
      },
      body: body
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_TSPCALIBRATIONREMINDER_UPDATE_URI}`, requestOptions);
      let status = await res.status;
      let data = await res.text();
      if (status == 204) {
        console.log(data);
        enqueueSnackbar(`TSP Calibration updated: ${props.data.FleetName}`, { variant: 'success' });
      }
      else {
        enqueueSnackbar("Error", { variant: 'error' })
      }
    }
    catch (error) {
      enqueueSnackbar("Error", { variant: 'error' })
      console.log(error);
    }

  }

  const updateReportReminder = async () => {
    const code = process.env.REACT_APP_FUNCTION_REPORTINGREMINDER_UPDATE_CODE;
    var body = JSON.stringify({
      "ReportReminderID": `${reportReminderID}`,
      "Frequency": `${reportingFrequency}`,
      "DaysBeforeDue3": `${daysBeforeDue3}`,
      "DaysBeforeDue7": `${daysBeforeDue7}`,
      "DaysBeforeDue14": `${daysBeforeDue14}`,
      "IsEnabled": `${reportReminderEnabled}`,
      "ReminderEmails": `${reportingEmails}`,
      "StartDateAsString": `${reportingStartDate}`,
      "LastSentAsString": `${reportLastSent}`,
    });
    var requestOptions = {
      method: "PUT",
      headers: {
        "x-functions-key": `${code}`
      },
      body: body
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_REPORTINGREMINDER_UPDATE_URI}`, requestOptions);
      let status = await res.status;
      let data = await res.text();
      if (status == 204) {
        console.log(data);
        enqueueSnackbar(`Analytics Reporting updated: ${props.data.FleetName}`, { variant: 'success' });
      }
      else {
        enqueueSnackbar("Error", { variant: 'error' })
      }
    }
    catch (error) {
      enqueueSnackbar("Error", { variant: 'error' })
      console.log(error);
    }

  }

  const deleteFleet = async () => {
    const code = process.env.REACT_APP_FUNCTION_FLEET_DELETE_CODE;
    var body = JSON.stringify({
      "FleetID": `${fleetID}`,
      "ContractID": `${contractID}`,
      "ReportReminderID": `${reportReminderID}`,
      "TSPCalibrationReminderID": `${tspCalibrationReminderID}`
    });
    var requestOptions = {
      method: "DELETE",
      headers: {
        "x-functions-key": `${code}`
      },
      body: body
    };
    try {
      let res = await fetch(`${process.env.REACT_APP_FUNCTION_FLEET_DELETE_URI}`, requestOptions);
      let status = await res.status;
      let data = await res.text();
      if (status == 204) {
        console.log(data);
        enqueueSnackbar(`Fleet deleted: ${props.data.FleetName}`, { variant: 'success' });
      }
      else {
        enqueueSnackbar("Error", { variant: 'error' })
      }
    }
    catch (error) {
      enqueueSnackbar("Error", { variant: 'error' })
      console.log(error);
    }
    handleClose();
    handleCloseDelete();
  }

  const [value, setValue] = React.useState('1');

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };


  return (
    <>
      {
        <div>
          <button style={{ backgroundColor: "transparent", border: "none" }} onClick={handleShow}>{props.data.AirlineName}</button>
        </div>
      }
      <ThemeProvider theme={props.theme}>
        <CssBaseline />
        <Dialog
          fullScreen
          open={show}
          onClose={handleClose}
          PaperProps={{
            style: {
              boxShadow: 'none',
              background: 'black',
            },
          }}
        >
          <AppBar sx={{ position: 'relative', background: '#191c24' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {props.data.AirlineName} / {props.data.Airline} / {props.data.FleetName}
              </Typography>
              <IconButton aria-label="delete" size="large" onClick={updateFleet}>
                <SaveIcon fontSize="inherit" />
              </IconButton>
              <IconButton aria-label="delete" size="large" onClick={handleClickOpenDelete}>
                <RemoveCircleIcon sx={{ color: 'red' }} fontSize="inherit" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent


            sx={{ paddingLeft: 0 }}
          >
            <Box sx={{ width: '100%', marginTop: -3 }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example"
                    variant="fullWidth"
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <Tab icon={<DataArrayIcon />} iconPosition="start" label="Data" value="1" />
                    <Tab icon={<NotificationsActiveIcon />} iconPosition="start" label="Analytics Reporting" value="2" />
                    <Tab icon={<CompassCalibrationIcon />} iconPosition="start" label="TSP Calibration" value="3" />
                    <Tab icon={<HistoryIcon />} iconPosition="start" label="History Log" value="4" />
                    <Tab icon={<EnergySavingsLeafIcon />} iconPosition="start" label="Savings Reports" value="5" />
                  </TabList>
                </Box>
                <TabPanel value={"1"}>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div style={{ paddingBottom: 5 }}>
                            Fleet Information
                          </div>
                        </div>

                        <div className="row">
                          <div className="">
                            <TextField id="standard-basic" disabled size="small" value={fleetID} variant="standard" sx={{ width: 400 }} label="Fleet ID" />
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="">
                            <TextField id="standard-basic" disabled={disabled} size="small" defaultValue={props.data.FleetName} value={fleetName} variant="standard" label="Fleet Name" onChange={onInputFleetName} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="">
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <Airlines sx={{ mr: 1, my: 0.5 }} />
                              <TextField id="standard-basic" disabled={disabled} disa select size="small" defaultValue={modelID} onChange={onInputModelID} variant="standard" label="Model">
                                {props.models.map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>
                        </div>
                        <div className="row">
                          <div className="">
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FunctionsIcon sx={{ mr: 1, my: 0.5 }} />
                              <TextField id="standard-basic" disabled={disabled} type="number" size="small" value={defaultCI} onChange={onInputDefaultCI} variant="standard" label="Default CI" />
                            </Box>
                          </div>
                        </div>
                        <div className="row">
                          <div className="">
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <StorageIcon sx={{ mr: 1, my: 0.5 }} />
                              <TextField id="standard-basic" disabled={disabled} select size="small" defaultValue={dataSource} onChange={onInputDataSource} variant="standard" label="Data Source">
                                {props.dataSources.map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>
                        </div>
                        <div className="row">
                          <div className="">
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <DataThresholdingIcon sx={{ mr: 1, my: 0.5 }} />
                              <TextField id="standard-basic" disabled={disabled} select size="small" defaultValue={dataReceived} onChange={onInputDataReceived} variant="standard" label="Data Received" sx={{ width: 100 }}>
                                <MenuItem key="false" value={false}>
                                  {"No"}
                                </MenuItem>
                                <MenuItem key="true" value={true}>
                                  {"Yes"}
                                </MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </div>
                        <div className="row" style={{ paddingBottom: 30 }}>
                          <div className="">
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <GetAppIcon sx={{ mr: 1, my: 0.5 }} />
                              <TextField id="standard-basic" disabled={disabled} select size="small" defaultValue={deployment} onChange={onInputDeployment} variant="standard" label="Deployment">
                                {props.deployments.map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>
                        </div>
                        <br></br>

                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">

                            <div className="row">
                              <div>
                                TSP Version
                              </div>
                            </div>
                            <div className="row" style={{ paddingBottom: 30 }}>
                              <div className="">
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                  <FiberNewIcon sx={{ color: '#00d25b', mr: 1, my: 0.5 }} />
                                  <TextField sx={{ width: 300 }} id="standard-basic" disabled size="small" value={props.data.TSPVersion} variant="standard" label="" />
                                </Box>
                              </div>
                            </div>
                            <Divider sx={{ borderBottomColor: "#333" }} />
                            <br></br>
                            <div className="row">
                              <div>
                                Contract Information
                              </div>
                            </div>
                            <div className="row">
                              <TextField id="standard-basic" disabled size="small" value={moment(props.data.TrialStartDate).format('MMMM Do YYYY, h:mm a')} variant="standard" label="Trial Start Date" sx={{ width: 300 }} />
                            </div>
                            <br></br>
                            <div className="row">
                              <LocalizationProvider
                                dateAdapter={AdapterMoment} >
                                <MobileDatePicker
                                  label="Trial Start Date"
                                  inputFormat="MMMM Do YYYY"
                                  value={trialStartDate}
                                  onChange={handleTrialStartDate}
                                  renderInput={(params) =>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                      <TodayIcon sx={{ mr: 1, my: 0.5 }} />
                                      <TextField variant="standard" {...params} />
                                    </Box>
                                  }
                                  size="small"
                                  variant="standard"
                                />
                              </LocalizationProvider>
                            </div>
                            <br></br>
                            <div className="row">
                              <LocalizationProvider
                                dateAdapter={AdapterMoment} >
                                <MobileDatePicker
                                  label="Expiration Date"
                                  inputFormat="MMMM Do YYYY"
                                  value={expirationDate}
                                  onChange={handleExpirationDate}
                                  renderInput={(params) =>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                      <EventIcon sx={{ mr: 1, my: 0.5 }} />
                                      <TextField variant="standard" {...params} />
                                    </Box>
                                  }
                                  size="small"
                                  variant="standard"
                                />
                              </LocalizationProvider>
                            </div>
                            <br></br>
                            <div className="row">
                              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField id="standard-basic" disabled={disabled} select size="small" defaultValue={isContract} variant="standard" label="Is Contract" onChange={onInputIsContract} sx={{ width: 200 }} >
                                  <MenuItem key="false" value={false}>
                                    <PriorityHighIcon sx={{ color: 'yellow', mr: 1, my: 0.5 }} />
                                    {"TRIAL"}
                                  </MenuItem>
                                  <MenuItem key="true" value={true}>
                                    <Check sx={{ color: '#00d25b', mr: 1, my: 0.5 }} />
                                    {"COMMERCIAL"}
                                  </MenuItem>
                                </TextField>
                              </Box>
                            </div>
                            <div className="row" style={{ paddingBottom: 30 }}>
                              <TextField id="standard-basic" disabled={disabled} select size="small" defaultValue={isActive} variant="standard" label="Is Active" onChange={onInputIsActive} sx={{ width: 200 }}>
                                <MenuItem key="false" value={false}>
                                  <CloseIcon sx={{ color: 'red', mr: 1, my: 0.5 }} />
                                  {"DISABLED"}
                                </MenuItem>
                                <MenuItem key="true" value={true}>
                                  <Check sx={{ color: '#00d25b', mr: 1, my: 0.5 }} />
                                  {"ACTIVE"}
                                </MenuItem>
                              </TextField>
                            </div>

                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </TabPanel>

                <TabPanel value="3">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-11">
                        <div className="row">
                          <div style={{ paddingBottom: 5 }}>
                            TSP Calibration
                          </div>
                        </div>

                      </div>
                      <div className="col-md-1">
                        <BootstrapSwitchButton
                          checked={
                            tspCalibrationEnabled
                          } size="xs" onstyle="success" offstyle="dark"
                          onChange={(e) => {
                            setTspCalibrationEnabled(e);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>
                    {
                      tspCalibrationEnabled
                        ?
                        <div style={{ opacity: tspCalibrationEnabled ? 100 : 0 }}>
                          <div className="row" >
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                      <UpdateIcon sx={{ mr: 1, my: 0.5 }} />
                                      <TextField id="standard-basic"
                                        InputProps={{
                                          inputProps: { min: 3 }
                                        }}
                                        disabled={disabled} type="number" size="small" value={tspCalibrationFrequency} variant="standard" label="Frequency (Days)"
                                        onChange={onInputCalibrationFrequency} onKeyDown={(event) => {
                                          event.preventDefault();
                                        }} />
                                    </Box>
                                  </div>
                                  {
                                    tspCalibrationFrequency > 0
                                      ?
                                      <>
                                        <div className="row">
                                          <LocalizationProvider
                                            dateAdapter={AdapterMoment} >
                                            <MobileDateTimePicker
                                              minDate={moment().format()}
                                              label="Calibration Start Date"
                                              inputFormat="MMMM Do YYYY"
                                              value={tspCalibrationStartDate}
                                              onChange={handleTspCalibrationStartDate}
                                              renderInput={(params) =>
                                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                  <TodayIcon sx={{ mr: 1, my: 0.5 }} />
                                                  <TextField variant="standard" {...params} />
                                                </Box>
                                              }
                                              size="small"
                                              variant="standard"
                                            />
                                          </LocalizationProvider>
                                        </div>
                                        <div className="row">
                                          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <CompassCalibrationIcon sx={{ mr: 1, my: 0.5 }} />
                                            <TextField id="standard-basic"
                                              disabled size="small"
                                              value={moment(moment(tspCalibrationStartDate).add(tspCalibrationFrequency, 'days').toDate()).format("MMMM Do YYYY")}
                                              label="Next Calibration" variant="standard"
                                            />
                                          </Box>
                                        </div>
                                      </>
                                      :
                                      <></>
                                  }
                                </div>
                                <div className="col-md-6" style={{ marginTop: -25 }}>
                                  {
                                    tspCalibrationFrequency > 0
                                      ?
                                      <>
                                        <div className="row">
                                          <div className="">
                                            <br></br>
                                            <Autocomplete
                                              multiple
                                              id="tags-standard"
                                              options={emails}
                                              onChange={(event, value) => setCalibrationEmails(value)} // prints the selected value
                                              value={calibrationEmails}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  variant="standard"
                                                  label="Calibration Emails"
                                                  sx={{ width: "100%", minWidth: 400 }}
                                                />
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </>
                                      :
                                      <></>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <br></br>
                          {
                            tspCalibrationFrequency > 0
                              ?
                              <>
                                <br></br>
                                <div className="row">
                                  <FormLabel sx={{ marginTop: -4 }} id="send before due">Threshold</FormLabel>
                                </div>
                                <div className="row">
                                  <br></br>
                                  <FormGroup sx={{ marginTop: -2 }} row>
                                    <FormControlLabel control={
                                      <Checkbox
                                        disabled={disabled}
                                        sx={{
                                          color: pink[800],
                                          '&.Mui-checked': {
                                            color: pink[600],
                                          }
                                        }}
                                        checked={thresholdDays3}
                                        onChange={handleThreshold3Days}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    } label="3 days" />
                                    <FormControlLabel control={
                                      <Checkbox
                                        disabled={disabled}
                                        checked={thresholdDays7}
                                        onChange={handleThreshold7Days}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    } label="7 days" />
                                    <FormControlLabel control={
                                      <Checkbox
                                        disabled={disabled}
                                        checked={thresholdDays14}
                                        onChange={handleThreshold14Days}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    } label="14 days" />
                                  </FormGroup>
                                </div>
                              </>
                              :
                              <></>
                          }
                        </div>
                        :
                        <><br></br></>
                    }
                  </div>
                  <Divider></Divider>
                  <br></br>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div style={{ paddingBottom: 5 }}>
                            History
                          </div>
                        </div>
                        <div className="row">
                          <Box sx={{ height: 400, width: '100%' }}>
                            {
                              tspCalibrationReminderID
                                ?
                                <TSPCalibrationDataTable tspCalibrationEventData={tspCalibrationEventData} />
                                :
                                <></>
                            }
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel value="2">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-11">
                        <div className="row">
                          <div style={{ paddingBottom: 5 }}>
                            Analytics Reporting
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <BootstrapSwitchButton
                          checked={
                            reportReminderEnabled
                          } size="xs" onstyle="success" offstyle="dark"
                          onChange={(e) => {
                            setReportReminderEnabled(e);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>
                    {
                      reportReminderEnabled
                        ?
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <UpdateIcon sx={{ mr: 1, my: 0.5 }} />
                                    <TextField id="standard-basic"
                                      InputProps={{
                                        inputProps: { min: 3 }
                                      }}
                                      disabled={disabled} type="number" size="small" value={reportingFrequency} variant="standard" label="Frequency (Days)" onChange={onInputReportingFrequency} onKeyDown={(event) => {
                                        event.preventDefault();
                                      }} />
                                  </Box>
                                </div>
                                <div className="row">
                                  <LocalizationProvider
                                    dateAdapter={AdapterMoment} >
                                    <MobileDateTimePicker
                                      minDate={moment().format()}
                                      label="Reporting Start Date"
                                      inputFormat="MMMM Do YYYY"
                                      value={reportingStartDate}
                                      onChange={handleReportingStartDate}
                                      renderInput={(params) =>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                          <TodayIcon sx={{ mr: 1, my: 0.5 }} />
                                          <TextField variant="standard" {...params} />
                                        </Box>
                                      }
                                      size="small"
                                      variant="standard"
                                    />
                                  </LocalizationProvider>
                                </div>
                                <div className="row">
                                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <ScheduleSendIcon sx={{ mr: 1, my: 0.5 }} />
                                    <TextField id="standard-basic"
                                      disabled size="small"
                                      value={moment(moment(reportingStartDate).add(reportingFrequency, 'days').toDate()).format("MMMM Do YYYY")}
                                      label="Next Report Deadline" variant="standard"
                                    />
                                  </Box>

                                </div>

                              </div>
                              <div className="col-md-6" style={{ marginTop: -25 }}>
                                <div className="row">
                                  <div className="">
                                    <br></br>
                                    <Autocomplete
                                      multiple
                                      id="tags-standard"
                                      options={emails}
                                      onChange={(event, value) => setReportingEmails(value)} // prints the selected value
                                      value={reportingEmails}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          label="Reminder Emails"
                                          sx={{ width: "100%", minWidth: 400 }}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <></>
                    }
                    <br></br>
                    {
                      reportReminderEnabled
                        ?
                        <>
                          <br></br>
                          <div className="row">
                            <FormLabel sx={{ marginTop: -4 }} id="send before due">Start Sending Before Due</FormLabel>
                          </div>
                          <div className="row" style={{}}>
                            <br></br>
                            <FormGroup sx={{ marginTop: -2 }} row>
                              <FormControlLabel control={
                                <Checkbox
                                  disabled={disabled}
                                  sx={{
                                    color: pink[800],
                                    '&.Mui-checked': {
                                      color: pink[600],
                                    }
                                  }}
                                  checked={daysBeforeDue3}
                                  onChange={handleDaysBeforeDue3}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              } label="3 days" />
                              <FormControlLabel control={
                                <Checkbox
                                  disabled={disabled}
                                  checked={daysBeforeDue7}
                                  onChange={handleDaysBeforeDue7}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              } label="7 days" />
                              <FormControlLabel control={
                                <Checkbox
                                  disabled={disabled}
                                  checked={daysBeforeDue14}
                                  onChange={handleDaysBeforeDue14}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              } label="14 days" />
                            </FormGroup>
                          </div>
                        </>
                        :
                        <></>
                    }
                  </div>
                  <Divider></Divider>
                  <br></br>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div style={{ paddingBottom: 5 }}>
                            History
                          </div>
                        </div>
                        <div className="row">
                          <Box sx={{ height: 400, width: '100%' }}>
                            {
                              reportReminderID
                                ?
                                <ReportingDataTable reportEventData={reportEventData} />
                                :
                                <></>
                            }
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="4">
                  <CommentBox theme={props.theme} fleet={props.data.FleetID} user={props.user}></CommentBox>
                </TabPanel>
                <TabPanel value="5">
                  {
                    modelID
                      ?
                      <>
                        <AggregatedData airline={props.data.Airline} fleet={fleetName} model={modelID}></AggregatedData>
                        <SavingsReports airline={props.data.Airline} fleet={fleetName} model={modelID}></SavingsReports>
                      </>
                      :
                      <></>
                  }
                </TabPanel>
              </TabContext>
            </Box>

          </DialogContent>
        </Dialog>

        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Delete ${fleetName}`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete {fleetName}? Data associated will be permanently erased.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDelete}>No</Button>
            <Button color="error" onClick={deleteFleet} sx={{ fontWeight: "bold" }}>Yes</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Modal show={false} onHide={handleClose} size="lg" scrollable="true" backdrop="static" contentClassName={"modal"}
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>{props.data.AirlineName} / {props.data.Airline} / {props.data.FleetName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ThemeProvider theme={props.theme}>
            <CssBaseline />

          </ThemeProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>


  );

}